import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { useAuth } from "@/auth/AuthContext";
import { post } from "@/lib/rest";
import Logo from "../assets/logo.svg";

export function Login() {
  const { setIsAuthenticated }: any = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    post({ url: "/auth/login", data: { email, password } })
      .then((response: any) => {
        const token = response?.data?.token;
        localStorage.setItem("site", token);
        setIsAuthenticated("authenticated");
        navigate("/home");
      })
      .catch((error: any) => {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: error.response.data.message + "",
        });
        console.error("Login failed:", error);
      });
  };

  return (
    <div className="h-screen w-full text-slate-900 lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[468px] gap-6 rounded-md border border-slate-300 p-8">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-muted-foreground text-balance text-slate-400">
              Enter your email below to login to your account
            </p>
          </div>
          <form
            onSubmit={onSubmit}
            className="grid w-[332px] gap-4 justify-self-center"
          >
            <div className="grid gap-2">
              <Label htmlFor="email" className="text-left">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">Password</Label>
                <a
                  href="/forgot-password"
                  className="ml-auto inline-block text-sm underline"
                >
                  Forgot your password?
                </a>
              </div>
              <Input
                id="password"
                type="password"
                placeholder=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button
              type="submit"
              className="my-6 w-full border-slate-200 bg-slate-900"
            >
              Login
            </Button>
            {/* Hidden to implement later */}
            {/* <Button variant="outline" className="w-full">
              Login with Google Auth
            </Button> */}
          </form>
          {/*Hidden to implement later */}
          {/* <div className="mt-4 w-[332px] justify-self-center text-center text-sm text-slate-900">
            Don&apos;t have an account?{" "}
            <a href="/signup" className="underline">
              Sign up
            </a>
          </div> */}
        </div>
      </div>
      <div className="flex h-full items-center justify-center bg-slate-900">
        <img
          src={Logo}
          alt="logo"
          style={{ height: 50, width: "auto" }}
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}
