/*
 * This example shows how you can use custom nodes and edges to dynamically add elements to your react flow graph.
 * A global layouting function calculates the new positions for the nodes every time the graph changes and animates existing nodes to their new position.
 *
 * There are three ways of adding nodes to the graph:
 *  1. Click an existing node: Create a new child node of the clicked node
 *  2. Click on the plus icon of an existing edge: Create a node in between the connected nodes of the edge
 *  3. Click a placeholder node: Turn the placeholder into a "real" node to prevent jumping of the layout
 *
 * The graph elements are added via hook calls in the custom nodes and edges. The layout is calculated every time the graph changes (see hooks/useLayout.ts).
 */
import { useState, useEffect } from 'react';
import {
  ReactFlow,
  Background,
  Edge,
  Node,
  ProOptions,
  ReactFlowProvider,
  Panel
} from '@xyflow/react';
import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";

import useLayout from './hooks/useLayout';
import nodeTypes from './nodeTypes';
import edgeTypes from './edgeTypes';
import {
  WorkflowPanel,
  PropertyPanel,
} from "@/custom/Workflow/Panels";
import { del, get, post } from "@/lib/rest";

import '@xyflow/react/dist/style.css';

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };
// initial setup: one workflow node and a placeholder node
// placeholder nodes can be turned into a workflow node by click
const defaultNodes: Node[] = [
  {
    id: '2',
    data: { label: '+' },
    position: { x: 65, y: 0 },
    type: 'placeholder',
  },
];

// initial setup: connect the workflow node to the placeholder node with a placeholder edge
const defaultEdges: Edge[] = [];

const fitViewOptions = {
  padding: 0.95,
};

function ReactFlowPro() {
  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout();
  const { projectId } = useParams();

  const [currentNode, setCurrentNode] = useState<Node | undefined | null>();
  const [workflows, setWorkflows] = useState([]);
  const [workflowName, setWorkflowName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await get({ url: `/api/${projectId}/workflows` });
    const workflows = response.data.workflows.map((workflow: any) => {
      return {
        ...workflow,
        configuration: JSON.parse(workflow.configuration),
      };
    });
    setWorkflows(workflows);
  };

  const onNodeClick = (_: React.MouseEvent, node: Node) => {
    setCurrentNode(node);
  };

  const updateNode = (newNode: Node) => { };

  const newWorkflow = () => { };

  const selectWorkflow = (workflow: any) => { };

  const saveConfiguration = async () => {
  };

  const deleteConfiguration = async () => {
  };

  return (
    <div className="flex h-full flex-grow flex-row relative">
      <WorkflowPanel
        workflows={workflows}
        newWorkflow={newWorkflow}
        selectWorkflow={selectWorkflow}
      />
      {currentNode && (
        <PropertyPanel node={currentNode} updateNode={updateNode} />
      )}
      <ReactFlow
        defaultNodes={defaultNodes}
        defaultEdges={defaultEdges}
        proOptions={proOptions}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={0.2}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        // we are setting deleteKeyCode to null to prevent the deletion of nodes in order to keep the example simple.
        // If you want to enable deletion of nodes, you need to make sure that you only have one root node in your graph.
        deleteKeyCode={null}
      >
        <Panel position="top-center">
          <input
            className="rounded-md border-2 px-4 py-2"
            type="text"
            placeholder="workflow name"
            value={""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWorkflowName(e.target.value)
            }
          />
        </Panel>
        <Panel position="top-right">
          <Button
            className="mr-2 w-fit bg-green-300 font-bold text-slate-800"
            onClick={() => {
              saveConfiguration();
            }}
          >
            Save
          </Button>
          <Button
            className="mr-2 w-fit bg-red-400 font-bold text-slate-800"
            onClick={() => {
              deleteConfiguration();
            }}
          >
            Delete
          </Button>
        </Panel>
        <Background />
      </ReactFlow>
    </div>
  );
}

function ReactFlowWrapper() {
  return (
    <div className="h-screen w-screen">
      <ReactFlowProvider>
        <ReactFlowPro />
      </ReactFlowProvider>
    </div>
  );
}

export default ReactFlowWrapper;
