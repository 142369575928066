import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const DeleteField = ({
  config,
  showDeleteFieldModal,
  handleCancelDeleteFieldModal,
  deleteField,
}: any) => {
  // Find the table with the given tableId
  const table = config.tables.find(
    (t: { id: string }) => t.id === showDeleteFieldModal.tableId,
  );

  // Find the field with the given fieldId
  const field = table?.columns.find(
    (f: { id: string }) => f.id === showDeleteFieldModal.fieldId,
  );

  return (
    <div className="absolute left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <Card className="flex justify-start">
        <CardHeader>
          <CardTitle className="w-fit">
            You are about to delete {field?.name}
          </CardTitle>
          <CardContent className="w-fit pl-0">
            <div className="w-fit">
              This will permanently delete {field?.name} from {table?.name}. Are
              you sure?
            </div>
          </CardContent>
          <div className="flex w-full justify-end">
            <div className="space-x-2">
              <Button
                variant="outline"
                onClick={() => handleCancelDeleteFieldModal()}
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  deleteField(
                    showDeleteFieldModal.tableId,
                    showDeleteFieldModal.fieldId,
                  );
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};

export default DeleteField;
