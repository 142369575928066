import axios from "axios";

const apiServer = process.env.REACT_APP_API_SERVER;

let config = {
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

export const get = ({ url }: { url: string }) => {
  return axios.request({ method: "get", ...config, url: `${apiServer}${url}` });
};

export const post = ({ url, data }: { url: string; data: any }) => {
  return axios.request({
    method: "post",
    ...config,
    url: `${apiServer}${url}`,
    data,
  });
};

export const del = ({ url, data }: { url: string; data: any }) => {
  return axios.request({
    method: "delete",
    ...config,
    url: `${apiServer}${url}`,
    data,
  });
};
