import React from "react";
import NavComponent from "@/custom/Navigation/NavComponent";
import { Outlet } from "react-router-dom";

const Project = () => {
  return (
    <div className="h-full w-full">
      <NavComponent />
      <Outlet />
    </div>
  );
};

export default Project;
