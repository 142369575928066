import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import {
  CalendarClock,
  ChevronDown,
  ChevronUp,
  FileDigit,
  FileType2,
  Hash,
  Key,
  Plus,
  ToggleLeft,
  Image,
  File,
  Bold,
  Eye,
  EyeOff,
} from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { z } from "zod";
import { useEffect, useState } from "react";
import { post } from "@/lib/rest";
import { DatabaseConfig, Table } from "../../pages/interface/config";

const CreateTableModal = ({
  projectId,
  config,
  handleFieldChange,
  handleForeignKeyChange,
  handleTableNameChange,
  addField,
  handleDeleteFieldModal,
  selectedFieldId,
  selectedTableId,
  setSelectedFieldId,
  handleCancelTable,
  setShowCreateTableModal,
  isEditMode = false,
  setIsEditMode,
}: {
  projectId: string;
  config: DatabaseConfig;
  handleFieldChange: any;
  handleForeignKeyChange: any;
  handleTableNameChange: any;
  addField: any;
  handleDeleteFieldModal: any;
  selectedFieldId: any;
  selectedTableId: any;
  setSelectedFieldId: any;
  handleCancelTable: any;
  setShowCreateTableModal: any;
  isEditMode?: boolean;
  setIsEditMode?: any;
}) => {
  const { toast } = useToast();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (selectedFieldId && selectedTableId) {
      const type = config.tables
        .find((table: any) => table.id === selectedTableId)
        ?.columns.find((column: any) => column.id === selectedFieldId)?.type;
      if (type === "foreignKey") {
        setShowDetails(true);
      }
    }
  }, [config, selectedFieldId, selectedTableId]);

  const handleSelectedFieldId = (column: any, index: any) => {
    if (index === 0 && column?.editable === false) {
      toast({
        variant: "destructive",
        title: "Uh oh! You cannot edit this column.",
        description: "This column is a primary key and cannot be edited.",
      });
      selectedFieldId !== null && setSelectedFieldId(null);
    } else if (column?.editable === false && column?.type === "foreignKey") {
      toast({
        variant: "destructive",
        title: "Uh oh! You cannot edit this column.",
        description: "This column is a foreign key and cannot be edited.",
      });
      // selectedFieldId !== null && setSelectedFieldId(null);
      setSelectedFieldId(column?.id);
    } else {
      setSelectedFieldId(column?.id);
    }
  };

  const uniqueFieldNames = (columns: any[]) => {
    const fieldNames = columns.map((column: { name: any }) => column?.name);
    const uniqueFieldNames = new Set(fieldNames);
    return uniqueFieldNames.size === fieldNames.length;
  };

  const noSpaces = (name: string) => !/\s/.test(name);

  const fieldSchema = z.object({
    id: z.string(),
    name: z
      .string()
      .min(1, "Field name is required")
      .refine(noSpaces, { message: "Field name must not contain spaces" }),
    type: z.enum([
      "text",
      "autoIncrementIntegerId",
      "number",
      "dateTime",
      "foreignKey",
      "boolean",
      "image",
      "file",
    ]),
    dataType: z.string().min(1, "Field data type is required"),
    editable: z.boolean().optional(),
    hidden: z.boolean().optional(),
    private: z.boolean().optional(),
    hash: z.boolean(),
    constraints: z
      .object({
        nullable: z.boolean(),
        unique: z.boolean(),
        default: z.boolean(),
        defaultValue: z.string(),
        primaryKey: z.boolean(),
      })
      .refine(
        (constraints) => {
          if (constraints.default) {
            return constraints.defaultValue !== "";
          }
          return true;
        },
        {
          message: "Default value must not be empty",
        },
      ),
  });

  // WIP - validation for foreign keys
  const foreignKeySchema = z.object({
    id: z.string(),
    name: z.string().optional(),
    column: z.string(),
    referenceTable: z.string().min(1, "Reference table is required"),
    relationshipType: z.enum(["ONE-ONE", "ONE-MANY", "MANY-MANY"]),
    onDelete: z.enum(["RESTRICT", "CASCADE", "SET NULL", "NO ACTION"]),
    onUpdate: z.enum(["RESTRICT", "CASCADE", "SET NULL", "NO ACTION"]),
  });

  const formSchema = z
    .object({
      name: z
        .string()
        .min(1, "Table name is required")
        .refine(noSpaces, { message: "Table name must not contain spaces" }),
      columns: z.array(fieldSchema).refine(uniqueFieldNames, {
        message: "Field names must be unique",
      }),
      foreignKeys: z.array(foreignKeySchema),
    })
    .refine(
      (data) => {
        const foreignKeyColumns = data.columns
          .filter((column) => column.type === "foreignKey")
          .map((column) => column.id);

        const definedForeignKeys = data.foreignKeys.map((fk) => fk.column);

        return foreignKeyColumns.every((columnId) =>
          definedForeignKeys.includes(columnId),
        );
      },
      {
        message:
          "Every foreign key field must have a corresponding entry in the foreignKeys array",
        path: ["foreignKeys"],
      },
    )
    .refine(
      (data) => {
        const nameOccurrences = config.tables.filter(
          (table) => table.name === data.name,
        ).length;
        return nameOccurrences < 2;
      },
      {
        message: "Table name must be unique",
        path: ["name"],
      },
    );

  const handleCreateWithValidation = () => {
    try {
      formSchema.parse(table);
      // handleCreate();

      // Set the updated config
      updateProjectData(projectId);
      setSelectedFieldId(null);
      setIsEditMode(false);
    } catch (e) {
      if (e instanceof z.ZodError) {
        console.log(e.errors);
        e.errors.forEach((error) => {
          toast({
            variant: "destructive",
            title: "Validation Error",
            description: error.message,
          });
        });
      }
    }
  };

  const toggleDetails = () => setShowDetails(!showDetails);
  const table = config.tables.find(
    (t: any) => t.id === selectedTableId,
  ) as Table;
  function updateProjectData(projectId: any) {
    const updatedConfig = { ...config };
    updatedConfig.tables.forEach((table) => {
      table.columns.forEach((column) => {
        if (column.type === "foreignKey") {
          column.editable = false;
        }
      });
    });
    const data = {
      projectId: projectId,
      newConfiguration: updatedConfig,
    };
    post({ url: "/api/configuration", data })
      .then((_) => {
        toast({
          variant: "default",
          title: `Table ${isEditMode ? "edited" : "created"} successfully`,
        });
        setShowCreateTableModal(false);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Error creating table",
          description: error.message,
        });
        console.error(error);
      });
  }
  useEffect(() => {
    document.getElementById("name")?.focus();
  }, []);

  return (
    <>
      <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
        <Card className="h-fit max-h-[80%] w-fit min-w-[40rem] overflow-y-auto">
          <CardHeader className="flex w-full justify-start">
            <CardTitle className="w-fit">
              {isEditMode ? "Edit" : "Create"} Table
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div>
              <div className="flex flex-col space-y-2">
                <Label htmlFor="name" className="w-fit">
                  Name
                </Label>
                <Input
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={table?.name}
                  onChange={(e) =>
                    handleTableNameChange(table.id, e.target.value)
                  }
                />
              </div>
            </div>
            <div>
              <div className="space-y-4 py-3">
                <h4 className="w-fit text-sm font-bold leading-none">Fields</h4>
                <div className="my-4" />
              </div>
              {/* Field Form */}
              {table?.columns?.length > 0 &&
                table?.columns?.map((column: any, index: number) => (
                  <div key={index} className="space-y-2">
                    <div
                      className="column-card flex w-full cursor-pointer justify-between rounded-md px-2 py-2 text-[14px] text-slate-500 hover:bg-slate-100"
                      onClick={() => handleSelectedFieldId(column, index)}
                    >
                      <div>{column?.name}</div>
                      <div className="flex space-x-4">
                        <div>{getFieldTypeLabel(column?.type)}</div>
                        {column?.type !== "autoIncrementIntegerId" && (
                          <>
                            <div>
                              {column?.constraints?.nullable
                                ? "Nullable"
                                : "Not Nullable"}
                            </div>
                            <div>
                              {column?.constraints?.default === false
                                ? "No Default"
                                : "Default"}
                            </div>
                            <div>
                              {column?.constraints?.unique
                                ? "Unique"
                                : "Not Unique"}
                            </div>
                            <div>
                              {column?.hidden ? "Hidden" : "Not Hidden"}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {selectedFieldId === column?.id &&
                      (column?.editable !== false ||
                        column?.type === "foreignKey") && (
                        <div>
                          <Card className="column-form mb-4 p-4">
                            <CardContent className="p-0">
                              <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                                <div className="flex flex-col space-y-2">
                                  <Label
                                    htmlFor={`field_name_${index}`}
                                    className="w-fit"
                                  >
                                    Name
                                  </Label>
                                  <Input
                                    disabled={
                                      column?.editable === false &&
                                      column?.type === "foreignKey"
                                    }
                                    type="text"
                                    id={`field_name_${index}`}
                                    placeholder="Name"
                                    value={column?.name}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        table.id,
                                        selectedFieldId,
                                        "name",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="flex flex-col space-y-2">
                                  <Label className="w-fit">Type</Label>
                                  <Select
                                    disabled={
                                      column?.editable === false &&
                                      column?.type === "foreignKey"
                                    }
                                    defaultValue="text"
                                    value={column?.type}
                                    onValueChange={(value: string) =>
                                      handleFieldChange(
                                        table.id,
                                        selectedFieldId,
                                        "type",
                                        value,
                                      )
                                    }
                                  >
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select a Type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectGroup>
                                        <SelectItem value="text">
                                          <div className="flex items-center space-x-2">
                                            <FileType2 className="h-4 w-4" />
                                            <p>Text</p>
                                          </div>
                                        </SelectItem>
                                        <SelectItem value="autoIncrementIntegerId">
                                          <div className="flex items-center space-x-2">
                                            <Hash className="h-4 w-4" />
                                            <p>Auto-increment integer id</p>
                                          </div>
                                        </SelectItem>
                                        <SelectItem value="number">
                                          <div className="flex items-center space-x-2">
                                            <FileDigit className="h-4 w-4" />
                                            <p>Number</p>
                                          </div>
                                        </SelectItem>
                                        <SelectItem value="dateTime">
                                          <div className="flex items-center space-x-2">
                                            <CalendarClock className="h-4 w-4" />
                                            <p> Date + Time</p>
                                          </div>
                                        </SelectItem>
                                        <SelectItem value="foreignKey">
                                          <div className="flex items-center space-x-2">
                                            <Key className="h-4 w-4" />
                                            <p> Foreign Key</p>
                                          </div>
                                        </SelectItem>{" "}
                                        <SelectItem value="boolean">
                                          <div className="flex items-center space-x-2">
                                            <ToggleLeft className="h-4 w-4" />
                                            <p> Boolean</p>
                                          </div>
                                        </SelectItem>
                                        <SelectItem value="image">
                                          <div className="flex items-center space-x-2">
                                            <Image className="h-4 w-4" />
                                            <p> Image</p>
                                          </div>
                                        </SelectItem>{" "}
                                        <SelectItem value="file">
                                          <div className="flex items-center space-x-2">
                                            <File className="h-4 w-4" />
                                            <p> File</p>
                                          </div>
                                        </SelectItem>
                                      </SelectGroup>
                                    </SelectContent>
                                  </Select>
                                </div>
                                {(column.type === "image" ||
                                  column.type === "file") && (
                                  <div className="flex items-center justify-between py-1">
                                    <div className="flex items-center space-x-2">
                                      <Label className="w-fit">Multiple</Label>
                                      <Switch
                                        checked={column?.multiple}
                                        onCheckedChange={(checked: boolean) =>
                                          handleFieldChange(
                                            table.id,
                                            selectedFieldId,
                                            "multiple",
                                            checked,
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex items-center space-x-2">
                                      <Label className="w-fit">Private</Label>
                                      <Switch
                                        checked={column?.private}
                                        onCheckedChange={(checked: boolean) =>
                                          handleFieldChange(
                                            table.id,
                                            selectedFieldId,
                                            "private",
                                            checked,
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {showDetails && (
                                  <>
                                    {column?.type === "number" && (
                                      <div className="col-span-2 flex flex-col space-y-2">
                                        <Label className="w-fit">
                                          Number Type
                                        </Label>
                                        <Select
                                          value={String(column?.dataType)}
                                          defaultValue="INTEGER"
                                          onValueChange={(value: string) => {
                                            handleFieldChange(
                                              table.id,
                                              selectedFieldId,
                                              "dataType",
                                              value,
                                            );
                                          }}
                                        >
                                          <SelectTrigger>
                                            <SelectValue placeholder="Select a type" />
                                          </SelectTrigger>
                                          <SelectContent>
                                            <SelectGroup>
                                              <SelectItem value="INTEGER">
                                                Integer
                                              </SelectItem>
                                              <SelectItem value="DECIMAL">
                                                Decimal
                                              </SelectItem>
                                              <SelectItem value="FLOAT">
                                                Float
                                              </SelectItem>
                                            </SelectGroup>
                                          </SelectContent>
                                        </Select>
                                      </div>
                                    )}
                                    {column?.type === "foreignKey" && (
                                      <>
                                        <div className="col-span-2 flex flex-col space-y-2">
                                          <Label className="w-fit">Table</Label>
                                          <Select
                                            disabled={
                                              column?.editable === false &&
                                              column?.type === "foreignKey"
                                            }
                                            value={
                                              config.tables
                                                .find(
                                                  (table) =>
                                                    table.id ===
                                                    selectedTableId,
                                                )
                                                ?.foreignKeys.find(
                                                  (foreignKey) =>
                                                    column.id ===
                                                    foreignKey.column,
                                                )?.referenceTable
                                            }
                                            // defaultValue={config.tables[0]?.id}
                                            onValueChange={(value: string) => {
                                              handleForeignKeyChange(
                                                table.id,
                                                selectedFieldId,
                                                "referenceTable",
                                                value,
                                              );
                                            }}
                                          >
                                            <SelectTrigger>
                                              <SelectValue placeholder="Select a Table" />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                {config.tables
                                                  .filter(
                                                    (table) =>
                                                      table.id !==
                                                      selectedTableId,
                                                  )
                                                  .map((table) => (
                                                    <SelectItem
                                                      key={table.id}
                                                      value={table.id}
                                                    >
                                                      {table.name}
                                                    </SelectItem>
                                                  ))}
                                              </SelectGroup>
                                            </SelectContent>
                                          </Select>
                                        </div>{" "}
                                        {config.tables
                                          .find(
                                            (table) =>
                                              table.id === selectedTableId,
                                          )
                                          ?.foreignKeys?.find(
                                            (fk) => fk.column === column.id,
                                          )?.referenceTable && (
                                          <div className="col-span-2 flex flex-col space-y-2">
                                            <Label className="w-fit">
                                              Relationship Type
                                            </Label>
                                            <Select
                                              disabled={
                                                column?.editable === false &&
                                                column?.type === "foreignKey"
                                              }
                                              value={(() => {
                                                const foundForeignKey =
                                                  config.tables
                                                    .flatMap(
                                                      (table) =>
                                                        table.foreignKeys,
                                                    )
                                                    .find(
                                                      (fk) =>
                                                        fk.column === column.id,
                                                    );

                                                return foundForeignKey?.relationshipType;
                                              })()}
                                              defaultValue={"ONE-ONE"}
                                              onValueChange={(
                                                value: string,
                                              ) => {
                                                handleForeignKeyChange(
                                                  table.id,
                                                  selectedFieldId,
                                                  "relationshipType",
                                                  value,
                                                );
                                              }}
                                            >
                                              <SelectTrigger>
                                                <SelectValue placeholder="Select a Type" />
                                              </SelectTrigger>
                                              <SelectContent>
                                                <SelectGroup>
                                                  <SelectItem value="ONE-ONE">
                                                    One to One
                                                  </SelectItem>
                                                  <SelectItem value="ONE-MANY">
                                                    One to Many
                                                  </SelectItem>
                                                  <SelectItem value="MANY-MANY">
                                                    Many to Many
                                                  </SelectItem>
                                                </SelectGroup>
                                              </SelectContent>
                                            </Select>
                                          </div>
                                        )}
                                        {config.tables
                                          .find(
                                            (table) =>
                                              table.id === selectedTableId,
                                          )
                                          ?.foreignKeys?.find(
                                            (fk) => fk.column === column.id,
                                          )?.referenceColumn && (
                                          <>
                                            {" "}
                                            <div className="flex flex-col space-y-2">
                                              <Label className="w-fit">
                                                On Delete
                                              </Label>
                                              <Select
                                                disabled={
                                                  column?.editable === false &&
                                                  column?.type === "foreignKey"
                                                }
                                                value={(() => {
                                                  const foundForeignKey =
                                                    config.tables
                                                      .flatMap(
                                                        (table) =>
                                                          table.foreignKeys,
                                                      )
                                                      .find(
                                                        (fk) =>
                                                          fk.column ===
                                                          column.id,
                                                      );

                                                  return foundForeignKey?.onDelete;
                                                })()}
                                                onValueChange={(value) => {
                                                  handleForeignKeyChange(
                                                    table.id,
                                                    selectedFieldId,
                                                    "onDelete",
                                                    value,
                                                  );
                                                }}
                                              >
                                                <SelectTrigger>
                                                  <SelectValue placeholder="Select an action" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                  <SelectGroup>
                                                    <SelectItem value="NO ACTION">
                                                      No Action
                                                    </SelectItem>
                                                    <SelectItem value="RESTRICT">
                                                      Restrict
                                                    </SelectItem>
                                                    <SelectItem value="SET NULL">
                                                      Set Null
                                                    </SelectItem>
                                                    <SelectItem value="SET DEFAULT">
                                                      Set Default
                                                    </SelectItem>
                                                    <SelectItem value="CASCADE">
                                                      Cascade
                                                    </SelectItem>
                                                  </SelectGroup>
                                                </SelectContent>
                                              </Select>
                                            </div>
                                            <div className="flex flex-col space-y-2">
                                              <Label className="w-fit">
                                                On Update
                                              </Label>
                                              <Select
                                                disabled={
                                                  column?.editable === false &&
                                                  column?.type === "foreignKey"
                                                }
                                                value={(() => {
                                                  const foundForeignKey =
                                                    config.tables
                                                      .flatMap(
                                                        (table) =>
                                                          table.foreignKeys,
                                                      )
                                                      .find(
                                                        (fk) =>
                                                          fk.column ===
                                                          column.id,
                                                      );

                                                  return foundForeignKey?.onUpdate;
                                                })()}
                                                onValueChange={(value) => {
                                                  handleForeignKeyChange(
                                                    table.id,
                                                    selectedFieldId,
                                                    "onUpdate",
                                                    value,
                                                  );
                                                }}
                                              >
                                                <SelectTrigger>
                                                  <SelectValue placeholder="Select an action" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                  <SelectGroup>
                                                    <SelectItem value="NO ACTION">
                                                      No Action
                                                    </SelectItem>
                                                    <SelectItem value="RESTRICT">
                                                      Restrict
                                                    </SelectItem>
                                                    <SelectItem value="SET NULL">
                                                      Set Null
                                                    </SelectItem>
                                                    <SelectItem value="SET DEFAULT">
                                                      Set Default
                                                    </SelectItem>
                                                    <SelectItem value="CASCADE">
                                                      Cascade
                                                    </SelectItem>
                                                  </SelectGroup>
                                                </SelectContent>
                                              </Select>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                    {column?.type !==
                                      "autoIncrementIntegerId" &&
                                      // column.type !== "foreignKey" &&
                                      column.type !== "image" &&
                                      column.type !== "file" && (
                                        <>
                                          {column.type !== "foreignKey" && (
                                            <>
                                              <div className="flex flex-col space-y-2">
                                                <Label className="w-fit">
                                                  Default
                                                </Label>
                                                <Select
                                                  value={String(
                                                    column?.constraints
                                                      ?.default,
                                                  )}
                                                  defaultValue="false"
                                                  onValueChange={(
                                                    value: string,
                                                  ) => {
                                                    handleFieldChange(
                                                      table.id,
                                                      selectedFieldId,
                                                      "default",
                                                      value,
                                                    );
                                                  }}
                                                >
                                                  <SelectTrigger>
                                                    <SelectValue placeholder="Select a Default" />
                                                  </SelectTrigger>
                                                  <SelectContent>
                                                    <SelectGroup>
                                                      <SelectItem value="false">
                                                        No Default
                                                      </SelectItem>
                                                      <SelectItem value="true">
                                                        Default
                                                      </SelectItem>
                                                    </SelectGroup>
                                                  </SelectContent>
                                                </Select>
                                              </div>

                                              {column?.constraints?.default ===
                                              true ? (
                                                column?.type === "boolean" ? (
                                                  <div className="flex flex-col space-y-2">
                                                    <Label className="w-fit">
                                                      Value
                                                    </Label>
                                                    <Select
                                                      value={String(
                                                        column?.constraints
                                                          ?.defaultValue,
                                                      )}
                                                      defaultValue="true"
                                                      onValueChange={(
                                                        value: string,
                                                      ) => {
                                                        handleFieldChange(
                                                          table.id,
                                                          selectedFieldId,
                                                          "defaultValue",
                                                          value,
                                                        );
                                                      }}
                                                    >
                                                      <SelectTrigger>
                                                        <SelectValue placeholder="Select a value" />
                                                      </SelectTrigger>
                                                      <SelectContent>
                                                        <SelectGroup>
                                                          <SelectItem value="true">
                                                            true
                                                          </SelectItem>
                                                          <SelectItem value="false">
                                                            false
                                                          </SelectItem>
                                                        </SelectGroup>
                                                      </SelectContent>
                                                    </Select>
                                                  </div>
                                                ) : column?.type ===
                                                  "dateTime" ? (
                                                  <div className="flex flex-col space-y-2">
                                                    <Label className="w-fit">
                                                      Value
                                                    </Label>
                                                    <Select
                                                      value={String(
                                                        column?.constraints
                                                          ?.defaultValue,
                                                      )}
                                                      defaultValue="CURRENT_TIMESTAMP"
                                                      onValueChange={(
                                                        value: string,
                                                      ) => {
                                                        handleFieldChange(
                                                          table.id,
                                                          selectedFieldId,
                                                          "defaultValue",
                                                          value,
                                                        );
                                                      }}
                                                    >
                                                      <SelectTrigger>
                                                        <SelectValue placeholder="Select a value" />
                                                      </SelectTrigger>
                                                      <SelectContent>
                                                        <SelectGroup>
                                                          <SelectItem value="CURRENT_TIMESTAMP">
                                                            Current Timestamp
                                                          </SelectItem>
                                                        </SelectGroup>
                                                      </SelectContent>
                                                    </Select>
                                                  </div>
                                                ) : (
                                                  <div className="flex flex-col space-y-2">
                                                    <Label className="w-fit">
                                                      Value
                                                    </Label>
                                                    <Input
                                                      type={
                                                        column?.type ===
                                                        "number"
                                                          ? "number"
                                                          : "text"
                                                      }
                                                      id={`default_${index}`}
                                                      placeholder="Default"
                                                      value={
                                                        column?.constraints
                                                          ?.defaultValue
                                                      }
                                                      onChange={(e) =>
                                                        handleFieldChange(
                                                          table.id,
                                                          selectedFieldId,
                                                          "defaultValue",
                                                          e.target.value,
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )
                                              ) : (
                                                <div></div>
                                              )}
                                            </>
                                          )}

                                          <div className="flex items-center justify-around space-x-2">
                                            <div className="flex items-center space-x-2">
                                              <Label className="w-fit">
                                                Nullable
                                              </Label>
                                              <Switch
                                                disabled={
                                                  config.tables
                                                    .find(
                                                      (table) =>
                                                        table.id ===
                                                        selectedTableId,
                                                    )
                                                    ?.foreignKeys.find(
                                                      (foreignKey) =>
                                                        column.id ===
                                                        foreignKey.column,
                                                    )?.relationshipType ===
                                                  "ONE-ONE"
                                                    ? true
                                                    : false ||
                                                        column?.constraints
                                                          ?.default
                                                      ? true
                                                      : false
                                                }
                                                checked={
                                                  column?.constraints?.nullable
                                                }
                                                onCheckedChange={(
                                                  checked: boolean,
                                                ) =>
                                                  handleFieldChange(
                                                    table.id,
                                                    selectedFieldId,
                                                    "nullable",
                                                    checked,
                                                  )
                                                }
                                              />
                                            </div>

                                            <div className="flex items-center space-x-2">
                                              <Label className="w-fit">
                                                Unique
                                              </Label>
                                              <Switch
                                                disabled={
                                                  config.tables
                                                    .find(
                                                      (table) =>
                                                        table.id ===
                                                        selectedTableId,
                                                    )
                                                    ?.foreignKeys.find(
                                                      (foreignKey) =>
                                                        column.id ===
                                                        foreignKey.column,
                                                    )?.relationshipType ===
                                                  "ONE-ONE"
                                                    ? true
                                                    : false ||
                                                        column?.constraints
                                                          ?.default
                                                      ? true
                                                      : false
                                                }
                                                checked={
                                                  column?.type === "foreignKey"
                                                    ? (() => {
                                                        const relationshipType =
                                                          config.tables
                                                            .find(
                                                              (table) =>
                                                                table.id ===
                                                                selectedTableId,
                                                            )
                                                            ?.foreignKeys?.find(
                                                              (fk) =>
                                                                fk.column ===
                                                                column.id,
                                                            )?.relationshipType;

                                                        return (
                                                          relationshipType ===
                                                            "ONE-ONE" ||
                                                          column?.constraints
                                                            ?.unique
                                                        );
                                                      })()
                                                    : column?.constraints
                                                        ?.unique
                                                }
                                                onCheckedChange={(
                                                  checked: boolean,
                                                ) =>
                                                  handleFieldChange(
                                                    table.id,
                                                    selectedFieldId,
                                                    "unique",
                                                    checked,
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {column.type !== "foreignKey" && (
                                            <div
                                              className={`flex items-start justify-around space-x-2`}
                                            >
                                              <div
                                                className={`flex items-center space-x-2`}
                                              >
                                                <Label className="w-fit">
                                                  Hidden
                                                </Label>
                                                <Switch
                                                  checked={column?.hidden}
                                                  onCheckedChange={(
                                                    checked: boolean,
                                                  ) =>
                                                    handleFieldChange(
                                                      table.id,
                                                      selectedFieldId,
                                                      "hidden",
                                                      checked,
                                                    )
                                                  }
                                                />
                                              </div>
                                              {column?.type === "text" ? (
                                                <div className="flex items-center space-x-2">
                                                  <Label className="w-fit">
                                                    Hash
                                                  </Label>
                                                  <Switch
                                                    checked={column?.hash}
                                                    onCheckedChange={(
                                                      checked: boolean,
                                                    ) =>
                                                      handleFieldChange(
                                                        table.id,
                                                        selectedFieldId,
                                                        "hash",
                                                        checked,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div className="min-w-[98px]"></div>
                                              )}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </>
                                )}

                                <div className="col-span-2 flex justify-between">
                                  <div className="flex items-center justify-center">
                                    <Button
                                      type="button"
                                      variant="ghost"
                                      className="w-fit bg-none text-red-500"
                                      onClick={() =>
                                        handleDeleteFieldModal(
                                          selectedTableId,
                                          selectedFieldId,
                                        )
                                      }
                                    >
                                      Delete Field
                                    </Button>
                                  </div>
                                  {showDetails
                                    ? column?.type !==
                                        "autoIncrementIntegerId" &&
                                      column?.type !== "image" &&
                                      column?.type !== "file" && (
                                        <Button
                                          type="button"
                                          variant="ghost"
                                          className="w-fit"
                                          onClick={toggleDetails}
                                        >
                                          See Less
                                          <ChevronUp className="ml-2.5 h-4 w-4" />
                                        </Button>
                                      )
                                    : column?.type !==
                                        "autoIncrementIntegerId" &&
                                      column?.type !== "image" &&
                                      column?.type !== "file" && (
                                        <Button
                                          onClick={toggleDetails}
                                          type="button"
                                          variant="ghost"
                                          className="w-fit"
                                        >
                                          See More
                                          <ChevronDown className="ml-2.5 h-4 w-4" />
                                        </Button>
                                      )}
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      )}
                  </div>
                ))}
              <div className="my-4" />
              <Button
                type="button"
                variant="secondary"
                className="w-full"
                onClick={() => {
                  addField(table.id);
                }}
              >
                <Plus className="mr-4" /> Add Field
              </Button>
            </div>
          </CardContent>
          <CardFooter className="flex justify-end space-x-2">
            <Button
              className="w-fit"
              variant="outline"
              onClick={() => {
                handleCancelTable();
              }}
            >
              Cancel
            </Button>
            <Button className="w-fit" onClick={handleCreateWithValidation}>
              {isEditMode ? "Save" : "Create"}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default CreateTableModal;

// Utility function to get column type label based on type
function getFieldTypeLabel(type: string) {
  switch (type) {
    case "text":
      return "Text";
    case "autoIncrementIntegerId":
      return "Auto-increment integer id";
    case "number":
      return "Number";
    case "dateTime":
      return "Date + Time";
    case "foreignKey":
      return "Foreign Key";
    case "boolean":
      return "Boolean";
    case "image":
      return "Image";
    case "file":
      return "File";
    default:
      return "";
  }
}
