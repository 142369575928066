import { useEffect } from "react";
import { Editor } from "@monaco-editor/react";
import { DatabaseConfig } from "../interface/config";

export const DataEditor = ({
  configuration,
  script,
  setScript,
}: {
  configuration: DatabaseConfig;
  script: any;
  setScript: any;
}) => {
  useEffect(() => {
    setScript(JSON.stringify(configuration, null, "\t"));
  }, [configuration, setScript]);

  function handleEditorChange(value: any, event: any) {
    setScript(value);
  }

  return (
    <>
      <Editor
        className="mt-4"
        height="calc(100vh - 120px)"
        defaultLanguage="json"
        value={script}
        onChange={handleEditorChange}
        defaultValue=""
      />
      {/* {validationErrors && (
        <div className="error-message">
          <pre>{validationErrors}</pre>
        </div>
      )} */}
    </>
  );
};
