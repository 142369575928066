import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";
import {
  FileType2,
  FileDigit,
  CalendarClock,
  Key,
  Hash,
  ToggleLeft,
  Image,
  File,
} from "lucide-react";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { post } from "@/lib/rest";
import { Column, DatabaseConfig, Table } from "@/pages/interface/config";
import { useEffect } from "react";

const CreateFieldModal = ({
  config,
  selectedTableId,
  selectedFieldId,
  setSelectedFieldId,
  handleFieldChange,
  handleForeignKeyChange,
  closeModal,
  setShowCreateFieldModal,
  isEditMode = false,
  projectId,
  fetchProjectData,
  setIsEditMode,
  dataCache,
}: {
  config: DatabaseConfig;
  selectedTableId: any;
  selectedFieldId: any;
  setSelectedFieldId: any;
  handleFieldChange: any;
  handleForeignKeyChange: any;
  closeModal: any;
  setShowCreateFieldModal: any;
  isEditMode?: boolean;
  projectId: string;
  fetchProjectData: any;
  setIsEditMode: any;
  dataCache: any;
}) => {
  useEffect(() => {
    document.getElementById("fieldName")?.focus();
  }, []);

  const table = config.tables.find(
    (t: any) => t.id === selectedTableId,
  ) as Table;
  const field = table.columns.find(
    (f: any) => f.id === selectedFieldId,
  ) as Column;

  const foreignKeyofthisField = table.foreignKeys.find(
    (f: any) => f.column === selectedFieldId,
  );

  const fieldSchema = z
    .object({
      name: z.string().min(1, "Field name cannot be empty"),
      type: z.string().min(1, "Field type cannot be empty"),
      dataType: z.string().min(1, "Field data type cannot be empty"),
      editable: z.boolean(),
      hidden: z.boolean(),
      private: z.boolean(),
      hash: z.boolean(),
      multiple: z.boolean(),
      constraints: z.object({
        nullable: z.boolean(),
        unique: z.boolean(),
        default: z.boolean(),
        defaultValue: z
          .string()
          .optional()
          .refine(
            (val) => !field.constraints.default || val !== "",
            "Field default value cannot be empty",
          ),
        primaryKey: z.boolean(),
      }),
    })
    .refine((val) => {
      const table = config.tables.find(
        (t: any) => t.id === selectedTableId,
      ) as Table;
      const existingField = table.columns.find(
        (f: any) => f.name === val.name && f.id !== selectedFieldId,
      );
      return !existingField;
    }, "Field name must be unique ");

  const foreignKeySchema = z.object({
    id: z.string(),
    name: z.string().optional(),
    column: z.string(),
    referenceTable: z.string().min(1, "Reference table is required"),
    onDelete: z.enum(["RESTRICT", "CASCADE", "SET NULL", "NO ACTION"]),
    onUpdate: z.enum(["RESTRICT", "CASCADE", "SET NULL", "NO ACTION"]),
  });

  function validateAndDisplayToast(
    schemaResult:
      | z.SafeParseReturnType<
          {
            name: string;
            type: string;
            dataType: string;
            editable: boolean;
            hidden: boolean;
            private: boolean;
            hash: boolean;
            multiple: boolean;
            constraints: {
              nullable: boolean;
              unique: boolean;
              default: boolean;
              primaryKey: boolean;
              defaultValue?: string | undefined;
            };
          },
          {
            name: string;
            type: string;
            dataType: string;
            editable: boolean;
            hidden: boolean;
            private: boolean;
            hash: boolean;
            multiple: boolean;
            constraints: {
              nullable: boolean;
              unique: boolean;
              default: boolean;
              primaryKey: boolean;
              defaultValue?: string | undefined;
            };
          }
        >
      | z.SafeParseReturnType<
          {
            id: string;
            column: string;
            referenceTable: string;
            onDelete: "RESTRICT" | "CASCADE" | "SET NULL" | "NO ACTION";
            onUpdate: "RESTRICT" | "CASCADE" | "SET NULL" | "NO ACTION";
            name?: string | undefined;
          },
          {
            id: string;
            column: string;
            referenceTable: string;
            onDelete: "RESTRICT" | "CASCADE" | "SET NULL" | "NO ACTION";
            onUpdate: "RESTRICT" | "CASCADE" | "SET NULL" | "NO ACTION";
            name?: string | undefined;
          }
        >,
    schemaName: string,
  ) {
    if (!schemaResult.success) {
      toast({
        variant: "destructive",
        title: `${schemaName} Validation Error`,
        description: schemaResult.error.issues[0].message,
      });
      return false;
    }
    return true;
  }
  // Handle field creation or update with validation
  const handleSaveField = () => {
    const result = fieldSchema.safeParse(field);
    if (!validateAndDisplayToast(result, "Field")) return;
    // Assuming config, foreignKeySchema, and validateAndDisplayToast are defined elsewhere
    if (
      config.tables.find((t: any) =>
        t.foreignKeys.find((fk: any) => fk.column === selectedFieldId),
      )
    ) {
      const resultForeignKey = foreignKeySchema.safeParse(
        foreignKeyofthisField,
      );
      if (!validateAndDisplayToast(resultForeignKey, "Foreign Key")) return;
    }
    const data = {
      projectId: projectId,
      newConfiguration: config,
    };
    post({ url: "/api/configuration", data })
      .then((_) => {
        fetchProjectData(projectId);
        toast({
          variant: "default",
          title: `Table ${isEditMode ? "edited" : "created"} successfully`,
        });
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Error creating table",
          description: error.message,
        });
        console.error(error);
      });
    setSelectedFieldId(null);

    setShowCreateFieldModal(false);
    setIsEditMode(false);
  };

  return (
    <>
      {table?.columns.length > 0 && selectedFieldId !== null && (
        <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
          <Card className="field-form mb-4 w-[400px] p-4">
            <CardHeader className="flex w-full justify-start">
              <CardTitle className="w-fit">
                {isEditMode ? "Edit" : "Create"} Field
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                {/* Field Name Input */}
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label htmlFor={`fieldName`} className="w-fit">
                    Field Name
                  </Label>
                  <Input
                    type="text"
                    id={`fieldName`}
                    placeholder="Name"
                    value={field?.name || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        selectedTableId,
                        selectedFieldId,
                        "name",
                        e.target.value,
                      )
                    }
                  />
                </div>

                {/* Field Type Select */}
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Field Type</Label>
                  <Select
                    defaultValue="text"
                    value={field?.type || "text"}
                    onValueChange={(value: string) =>
                      handleFieldChange(
                        selectedTableId,
                        selectedFieldId,
                        "type",
                        value,
                      )
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="text">
                          <div className="flex items-center space-x-2">
                            <FileType2 className="h-4 w-4" />
                            <p>Text</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="autoIncrementIntegerId">
                          <div className="flex items-center space-x-2">
                            <Hash className="h-4 w-4" />
                            <p>Auto-increment integer id</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="number">
                          <div className="flex items-center space-x-2">
                            <FileDigit className="h-4 w-4" />
                            <p>Number</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="dateTime">
                          <div className="flex items-center space-x-2">
                            <CalendarClock className="h-4 w-4" />
                            <p>Date + Time</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="foreignKey">
                          <div className="flex items-center space-x-2">
                            <Key className="h-4 w-4" />
                            <p>Foreign Key</p>
                          </div>
                        </SelectItem>
                        <SelectItem value="boolean">
                          <div className="flex items-center space-x-2">
                            <ToggleLeft className="h-4 w-4" />
                            <p> Boolean</p>
                          </div>
                        </SelectItem>{" "}
                        <SelectItem value="image">
                          <div className="flex items-center space-x-2">
                            <Image className="h-4 w-4" />
                            <p> Image</p>
                          </div>
                        </SelectItem>{" "}
                        <SelectItem value="file">
                          <div className="flex items-center space-x-2">
                            <File className="h-4 w-4" />
                            <p> File</p>
                          </div>
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                {field?.type === "number" && (
                  <div className="col-span-2 flex flex-col space-y-2">
                    <Label className="w-fit">Number Type</Label>
                    <Select
                      value={String(field?.dataType)}
                      defaultValue="INTEGER"
                      onValueChange={(value: string) => {
                        handleFieldChange(
                          table.id,
                          selectedFieldId,
                          "dataType",
                          value,
                        );
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="INTEGER">Integer</SelectItem>
                          <SelectItem value="DECIMAL">Decimal</SelectItem>
                          <SelectItem value="FLOAT">Float</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}
                {field?.type === "foreignKey" && (
                  <>
                    <div className="col-span-2 flex flex-col space-y-2">
                      <Label className="w-fit">Table</Label>
                      <Select
                        value={
                          config.tables
                            .find((table) => table.id === selectedTableId)
                            ?.foreignKeys.find(
                              (foreignKey) => field.id === foreignKey.column,
                            )?.referenceTable
                        }
                        onValueChange={(value) => {
                          handleForeignKeyChange(
                            table.id,
                            selectedFieldId,
                            "referenceTable",
                            value,
                          );
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a Table" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {config.tables
                              .filter((table) => table.id !== selectedTableId)
                              .map((table) => (
                                <SelectItem key={table.id} value={table.id}>
                                  {table.name}
                                </SelectItem>
                              ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    {config.tables
                      .find((table) => table.id === selectedTableId)
                      ?.foreignKeys?.find((fk) => fk.column === field.id)
                      ?.referenceTable && (
                      <div className="col-span-2 flex flex-col space-y-2">
                        <Label className="w-fit">Relationship Type</Label>
                        <Select
                          value={(() => {
                            const foundForeignKey = config.tables
                              .flatMap((table) => table.foreignKeys)
                              .find((fk) => fk.column === field.id);

                            return foundForeignKey
                              ? foundForeignKey.relationshipType
                              : undefined;
                          })()}
                          defaultValue={"ONE-ONE"}
                          onValueChange={(value: string) => {
                            handleForeignKeyChange(
                              table.id,
                              selectedFieldId,
                              "relationshipType",
                              value,
                            );
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select a Type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="ONE-ONE">
                                One to One
                              </SelectItem>
                              <SelectItem value="ONE-MANY">
                                One to Many
                              </SelectItem>
                              <SelectItem value="MANY-MANY">
                                Many to Many
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                    {config.tables
                      .find((table) => table.id === selectedTableId)
                      ?.foreignKeys?.find((fk) => fk.column === field.id)
                      ?.referenceColumn && (
                      <>
                        {" "}
                        <div className="flex flex-col space-y-2">
                          <Label className="w-fit">On Delete</Label>
                          <Select
                            value={
                              config.tables
                                .find((table) => table.id === selectedTableId)
                                ?.foreignKeys.find(
                                  (foreignKey) =>
                                    field.id === foreignKey.column,
                                )?.onDelete
                            }
                            onValueChange={(value) => {
                              handleForeignKeyChange(
                                table.id,
                                selectedFieldId,
                                "onDelete",
                                value,
                              );
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select a Field" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="NO ACTION">
                                  No Action
                                </SelectItem>
                                <SelectItem value="RESTRICT">
                                  Restrict
                                </SelectItem>
                                <SelectItem value="SET NULL">
                                  Set Null
                                </SelectItem>
                                <SelectItem value="SET DEFAULT">
                                  Set Default
                                </SelectItem>
                                <SelectItem value="CASCADE">Cascade</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <Label className="w-fit">On Update</Label>
                          <Select
                            value={
                              config.tables
                                .find((table) => table.id === selectedTableId)
                                ?.foreignKeys.find(
                                  (foreignKey) =>
                                    field.id === foreignKey.column,
                                )?.onUpdate
                            }
                            onValueChange={(value) => {
                              handleForeignKeyChange(
                                table.id,
                                selectedFieldId,
                                "onUpdate",
                                value,
                              );
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select a Field" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="NO ACTION">
                                  No Action
                                </SelectItem>
                                <SelectItem value="RESTRICT">
                                  Restrict
                                </SelectItem>
                                <SelectItem value="SET NULL">
                                  Set Null
                                </SelectItem>
                                <SelectItem value="SET DEFAULT">
                                  Set Default
                                </SelectItem>
                                <SelectItem value="CASCADE">Cascade</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      </>
                    )}
                  </>
                )}
                {(field.type === "image" || field.type === "file") && (
                  <div className="col-span-2 flex items-center justify-between py-1">
                    <div className="flex items-center space-x-2">
                      <Label className="w-fit">Multiple</Label>
                      <Switch
                        checked={field?.multiple}
                        onCheckedChange={(checked: boolean) =>
                          handleFieldChange(
                            table.id,
                            selectedFieldId,
                            "multiple",
                            checked,
                          )
                        }
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <Label className="w-fit">Private</Label>
                      <Switch
                        checked={field?.private}
                        onCheckedChange={(checked: boolean) =>
                          handleFieldChange(
                            table.id,
                            selectedFieldId,
                            "private",
                            checked,
                          )
                        }
                      />
                    </div>
                  </div>
                )}
                {field.type !== "autoIncrementIntegerId" &&
                  field.type !== "image" &&
                  field.type !== "file" && (
                    <>
                      <div className="col-span-2 flex flex-col space-y-2">
                        {/* Default Value Section */}
                        {field.type !== "foreignKey" && (
                          <>
                            <Separator className="w-full" />
                            <div className="flex flex-col space-y-2 py-2">
                              <Label className="w-fit">Default</Label>
                              <Select
                                disabled={
                                  dataCache[
                                    config?.tables?.find(
                                      (table) => table.id === selectedTableId,
                                    )?.name || ""
                                  ]?.data.length > 0
                                }
                                value={
                                  field?.constraints?.default ? "true" : "false"
                                }
                                defaultValue="false"
                                onValueChange={(value: string) => {
                                  handleFieldChange(
                                    selectedTableId,
                                    selectedFieldId,
                                    "default",
                                    value,
                                  );
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a Default" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="false">
                                      No Default
                                    </SelectItem>
                                    <SelectItem value="true">
                                      Default
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </div>
                            {field?.constraints?.default === true &&
                              (field?.type === "boolean" ? (
                                <div className="flex flex-col space-y-2">
                                  <Label className="w-fit">Value</Label>
                                  <Select
                                    value={String(
                                      field?.constraints?.defaultValue,
                                    )}
                                    defaultValue="true"
                                    onValueChange={(value: string) => {
                                      handleFieldChange(
                                        table.id,
                                        selectedFieldId,
                                        "defaultValue",
                                        value,
                                      );
                                    }}
                                  >
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select a value" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectGroup>
                                        <SelectItem value="true">
                                          true
                                        </SelectItem>
                                        <SelectItem value="false">
                                          false
                                        </SelectItem>
                                      </SelectGroup>
                                    </SelectContent>
                                  </Select>
                                </div>
                              ) : field?.type === "dateTime" ? (
                                <div className="flex flex-col space-y-2">
                                  <Label className="w-fit">Value</Label>
                                  <Select
                                    value={String(
                                      field?.constraints?.defaultValue,
                                    )}
                                    defaultValue="CURRENT_TIMESTAMP"
                                    onValueChange={(value: string) => {
                                      handleFieldChange(
                                        table.id,
                                        selectedFieldId,
                                        "defaultValue",
                                        value,
                                      );
                                    }}
                                  >
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select a value" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectGroup>
                                        <SelectItem value="CURRENT_TIMESTAMP">
                                          Current Timestamp
                                        </SelectItem>
                                      </SelectGroup>
                                    </SelectContent>
                                  </Select>
                                </div>
                              ) : (
                                <div className="flex flex-col space-y-2">
                                  <Label className="w-fit">Value</Label>
                                  <Input
                                    type={
                                      field?.type === "number"
                                        ? "number"
                                        : "text"
                                    }
                                    id={`defaultValue`}
                                    placeholder="Default"
                                    value={field?.constraints?.defaultValue}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        table.id,
                                        selectedFieldId,
                                        "defaultValue",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              ))}
                          </>
                        )}
                        <Separator className="w-full" />

                        {/* Nullable and Unique Switches */}
                        <div className="flex items-center justify-between py-1">
                          <div className="flex items-center space-x-2">
                            <Label className="w-fit">Nullable</Label>
                            <Switch
                              disabled={
                                config.tables
                                  .find((table) => table.id === selectedTableId)
                                  ?.foreignKeys.find(
                                    (foreignKey) =>
                                      field.id === foreignKey.column,
                                  )?.relationshipType === "ONE-ONE"
                                  ? true
                                  : false || field?.constraints?.default
                                    ? true
                                    : false
                              }
                              checked={field?.constraints?.nullable}
                              onCheckedChange={(checked: boolean) =>
                                handleFieldChange(
                                  selectedTableId,
                                  selectedFieldId,
                                  "nullable",
                                  checked,
                                )
                              }
                            />
                          </div>
                          <div className="flex items-center space-x-2">
                            <Label className="w-fit">Unique</Label>
                            <Switch
                              disabled={
                                config.tables
                                  .find((table) => table.id === selectedTableId)
                                  ?.foreignKeys.find(
                                    (foreignKey) =>
                                      field.id === foreignKey.column,
                                  )?.relationshipType === "ONE-ONE"
                                  ? true
                                  : false || field?.constraints?.default
                                    ? true
                                    : false
                              }
                              checked={
                                field?.type === "foreignKey"
                                  ? (() => {
                                      const relationshipType = config.tables
                                        .find(
                                          (table) =>
                                            table.id === selectedTableId,
                                        )
                                        ?.foreignKeys?.find(
                                          (fk) => fk.column === field.id,
                                        )?.relationshipType;

                                      return (
                                        relationshipType === "ONE-ONE" ||
                                        field?.constraints?.unique
                                      );
                                    })()
                                  : field?.constraints?.unique
                              }
                              onCheckedChange={(checked: boolean) =>
                                handleFieldChange(
                                  table.id,
                                  selectedFieldId,
                                  "unique",
                                  checked,
                                )
                              }
                            />
                          </div>
                        </div>
                        {field.type !== "foreignKey" && (
                          <div className="flex items-center justify-between py-1">
                            <div className="flex items-center space-x-2">
                              <Label className="w-fit">Hidden</Label>
                              <Switch
                                checked={field?.hidden}
                                onCheckedChange={(checked: boolean) =>
                                  handleFieldChange(
                                    table.id,
                                    selectedFieldId,
                                    "hidden",
                                    checked,
                                  )
                                }
                              />
                            </div>
                            {field.type === "text" && (
                              <div className="flex items-center space-x-2">
                                <Label className="w-fit">Hash</Label>
                                <Switch
                                  checked={field?.hash}
                                  onCheckedChange={(checked: boolean) =>
                                    handleFieldChange(
                                      table.id,
                                      selectedFieldId,
                                      "hash",
                                      checked,
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
              </div>
            </CardContent>
            <CardFooter className="flex justify-end space-x-2 pt-4">
              <Button className="w-fit" variant="outline" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                className="w-fit"
                onClick={() => {
                  handleSaveField();
                }}
              >
                {isEditMode ? "Save" : "Create"}
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </>
  );
};

export default CreateFieldModal;
