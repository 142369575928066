import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Loader2, Plus } from "lucide-react";
import CreateTableModal from "@/custom/Modal/CreateTable";
import DeleteField from "@/custom/Modal/DeleteField";
import TableComp from "@/pages/data/Table";
import DeleteTable from "@/custom/Modal/DeleteTable";
import { Button } from "@/components/ui/button";
import CreateField from "@/custom/Modal/CreateField";
import { toast } from "@/components/ui/use-toast";
import { get, post } from "@/lib/rest";
import { DatabaseConfig, ForeignKey, Table } from "../interface/config";
import RenameTable from "@/custom/Modal/RenameTable";
import Loading from "@/custom/Loading/loading";
import { Data as DataType } from "../interface/config";
import _ from "lodash";

const Data = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [dbId, setDbId] = useState("");
  const [config, setConfig] = useState<DatabaseConfig>({
    version: "v1",
    database: "sqlite3",
    tables: [],
  });
  const [oldConfig, setOldConfig] = useState<DatabaseConfig>({
    version: "v1",
    database: "sqlite3",
    tables: [],
  });
  const [loading, setLoading] = useState(false);
  const [showCreateTableModal, setShowCreateTableModal] = useState(false);
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<{
    show: boolean;
    tableId: string | null;
    fieldId: string | null;
  }>({
    show: false,
    tableId: null,
    fieldId: null,
  });
  const [showDeleteTableModal, setShowDeleteTableModal] = useState<{
    show: boolean;
    tableIndex: number | null;
  }>({
    show: false,
    tableIndex: null,
  });

  const [selectedTableId, setSelectedTableId] = useState<string | null>(null);
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);
  const [showCreateFieldModal, setShowCreateFieldModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [projectData, setProjectData] = useState<any>([]);

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [syncing, setSyncing] = useState(false);

  function fetchProjectData(projectId: any) {
    setLoading(true);
    get({ url: `/api/project/${projectId}` })
      .then((response) => {
        setProjectData(response.data.projectData);
        const data = JSON.parse(
          response.data.projectData.configuration[0].new_configuration,
        );
        const oldData = JSON.parse(
          response.data.projectData.configuration[0].old_configuration,
        );
        setConfig(data);
        setOldConfig(oldData);
        setDbId(response.data.projectData.projects[0].db_connection_id);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    if (
      projectId &&
      showCreateFieldModal === false &&
      showCreateTableModal === false
    ) {
      fetchProjectData(projectId);
    }
  }, [
    projectId,
    showCreateFieldModal,
    showDeleteTableModal,
    showCreateTableModal,
  ]);

  const handleFieldChange = (
    tableId: string,
    fieldId: string,
    key: string,
    value: any,
  ) => {
    const updatedTables = config.tables.map((table) => {
      if (table.id !== tableId) {
        return table;
      }

      let updatedForeignKeys: ForeignKey[] = table.foreignKeys || [];

      if (key === "type" && value !== "foreignKey") {
        updatedForeignKeys = updatedForeignKeys.filter(
          (fk) => fk.column !== fieldId,
        );
      }

      const updatedColumns = table.columns.map((column) => {
        if (column.id !== fieldId) {
          return column;
        }

        const updatedColumn: any = { ...column };

        if (key === "dataType") {
          if (updatedColumn.type === "number") {
            updatedColumn.dataType = value;
          }
        }
        switch (value) {
          case "text":
            updatedColumn.dataType = "TEXT";
            break;
          case "number":
            updatedColumn.dataType = "INTEGER";
            break;
          case "foreignKey":
            updatedColumn.dataType = "INTEGER";
            break;
          case "boolean":
            updatedColumn.dataType = "BOOLEAN";
            break;
          case "image":
            updatedColumn.dataType = "TEXT";
            break;
          case "file":
            updatedColumn.dataType = "TEXT";
            break;
          case "dateTime":
            updatedColumn.dataType = "TIMESTAMP";
            break;
        }
        if (key === "default") {
          updatedColumn.constraints.default = value === "true";
          if (value === "false") {
            updatedColumn.constraints.defaultValue = "";
          }
        } else {
          if (updatedColumn.constraints.hasOwnProperty(key)) {
            updatedColumn.constraints[key] = value;
          } else {
            updatedColumn[key] = value;
          }
        }
        if (key === "default" && value === "true") {
          updatedColumn.constraints.nullable = false;
          updatedColumn.constraints.unique = false;
        }

        if (key === "type" && value === "foreignKey") {
          if (config.tables.length === 1) {
            toast({
              variant: "destructive",
              title: "No other tables available",
              description:
                "You need to create another table to create a foreign key.",
            });
          } else {
            const existingForeignKeyIndex = updatedForeignKeys.findIndex(
              (fk) => fk.column === fieldId,
            );

            if (existingForeignKeyIndex === -1) {
              // Add a new foreign key
              updatedForeignKeys.push({
                id: generateUUID(),
                name: "",
                column: fieldId,
                referenceTable: "",
                referenceColumn: "",
                relationshipType: "ONE-ONE",
                relationshipColumn: "",
                onDelete: "NO ACTION",
                onUpdate: "NO ACTION",
              });
            }
          }
        }
        if (key === "unique" && value === true) {
          const relationshipType = updatedForeignKeys.find(
            (fk) => fk.column === fieldId,
          )?.relationshipType;
          if (
            relationshipType === "ONE-ONE" ||
            relationshipType === "ONE-MANY" ||
            relationshipType === "MANY-MANY"
          ) {
            updatedColumn.constraints.nullable = false;
          }
        }
        if (key === "nullable" && value === true) {
          const relationshipType = updatedForeignKeys.find(
            (fk) => fk.column === fieldId,
          )?.relationshipType;

          if (
            relationshipType === "ONE-ONE" ||
            relationshipType === "ONE-MANY" ||
            relationshipType === "MANY-MANY"
          ) {
            updatedColumn.constraints.unique = false;
          }
        }

        return updatedColumn;
      });

      return {
        ...table,
        columns: updatedColumns,
        foreignKeys: updatedForeignKeys,
      };
    });

    setConfig({
      ...config,
      tables: updatedTables,
    });
  };
  const handleForeignKeyChange = (
    tableId: string,
    fieldId: string,
    key: string,
    value: any,
  ) => {
    let referenceTable: Table | undefined;
    const newForeignKeyColumnId = generateUUID();
    const updatedTables = config.tables.map((table) => {
      if (table.id !== tableId) {
        return table;
      }

      const updatedForeignKeys = table.foreignKeys.map((fk) => {
        if (fk.column !== fieldId) {
          return fk;
        }

        let updatedForeignKey = { ...fk, [key]: value };

        if (key === "referenceTable") {
          referenceTable = config.tables.find((t) => t.id === value);

          if (referenceTable) {
            const referenceColumn =
              referenceTable.columns.find(
                (col) => col.constraints.primaryKey,
              ) || referenceTable.columns[0];

            const columnName = config.tables
              .find((table) => table.id === tableId)
              ?.columns.find((col) => col.id === fieldId)?.name;

            if (referenceColumn) {
              updatedForeignKey = {
                ...updatedForeignKey,
                referenceColumn: referenceColumn.id,
                name: `${columnName}_${referenceColumn.name}_fk`,
              };
            }
          }
        }
        if (key === "relationshipType" && value === "MANY-MANY") {
          const referenceTableID = config.tables
            .find((t) => t.id === tableId)
            ?.foreignKeys.find((fk) => fk.column === fieldId)?.referenceTable;

          const referenceTableName = config.tables.find(
            (t) => t.id === referenceTableID,
          )?.name;
          const columnName = config.tables
            .find((table) => table.id === tableId)
            ?.columns.find((col) => col.id === fieldId)?.name;

          updatedForeignKey = {
            ...updatedForeignKey,
            name: `${columnName}_${referenceTableName}_MtoM_fk`,
            relationshipColumn: newForeignKeyColumnId,
          };
        }

        return updatedForeignKey;
      });

      const updatedColumns = table.columns.map((column) => {
        if (column.id !== fieldId) {
          return column;
        }

        const updatedColumn: any = { ...column };

        if (key === "relationshipType" && value === "MANY-MANY") {
          updatedColumn.constraints.unique = false;
          const referenceTableID = config.tables
            .find((t) => t.id === tableId)
            ?.foreignKeys.find((fk) => fk.column === fieldId)?.referenceTable;
          referenceTable = config.tables.find((t) => t.id === referenceTableID);
        }
        if (key === "relationshipType" && value === "ONE-ONE") {
          updatedColumn.constraints.unique = true;
          updatedColumn.constraints.nullable = false;
        }
        if (key === "relationshipType" && value === "ONE-MANY") {
          updatedColumn.constraints.unique = false;
        }

        return updatedColumn;
      });

      return {
        ...table,
        foreignKeys: updatedForeignKeys,
        columns: updatedColumns,
      };
    });

    setConfig({
      ...config,
      tables: updatedTables,
    });
    if (key === "relationshipType" && value === "MANY-MANY" && referenceTable) {
      const newUpdatedTables = updatedTables.map((table) => {
        if (table.id === referenceTable!.id) {
          const table1: any = config.tables.find(
            (table) => table.id === tableId,
          );
          const baseForeignKeyName = `${table1?.name}s`;
          let newForeignKeyName = baseForeignKeyName;

          if (
            table1 &&
            table.columns.some(
              (column: { name: string }) => column.name === newForeignKeyName,
            )
          ) {
            let counter = 1;
            while (
              table.columns.some(
                (column: { name: string }) =>
                  column.name === `${baseForeignKeyName}${counter}`,
              )
            ) {
              counter++;
            }
            newForeignKeyName = `${baseForeignKeyName}${counter}`;
          }

          const newForeignKeyColumn = {
            id: newForeignKeyColumnId,
            name: newForeignKeyName.trim(),
            type: "foreignKey",
            dataType: "INTEGER",
            editable: false,
            hidden: false,
            private: false,
            hash: false,
            multiple: false,
            constraints: {
              nullable: false,
              unique: false,
              default: false,
              defaultValue: "",
              primaryKey: false,
            },
          };
          const updatedColumns = [...table.columns, newForeignKeyColumn];
          const newForeignKeyObjectId = generateUUID();
          const newForeignKeyObjectName = `${
            config.tables
              .find((table) => table.id === tableId)
              ?.columns.find((col) => col.id === fieldId)?.name
          }_${referenceTable!.name}_MtoM_fk`;
          const newForeignKeyObject = {
            id: newForeignKeyObjectId,
            name: newForeignKeyObjectName,
            column: newForeignKeyColumnId,
            referenceTable: tableId,
            referenceColumn:
              config.tables
                .find((table) => table.id === tableId)
                ?.columns.find((col) => col.constraints.primaryKey)?.id ||
              config.tables.find((table) => table.id === tableId)?.columns[0]
                ?.id ||
              "",
            relationshipType: "MANY-MANY",
            relationshipColumn: fieldId,
            onDelete: "NO ACTION",
            onUpdate: "NO ACTION",
          };

          const updatedForeignKeys = [
            ...table.foreignKeys,
            newForeignKeyObject,
          ];
          return {
            ...table,
            columns: updatedColumns,
            foreignKeys: updatedForeignKeys,
          };
        }

        return table;
      });

      setConfig({
        ...config,
        tables: newUpdatedTables,
      });
    }
  };

  const handleTableNameChange = (tableId: string, value: string) => {
    const updatedTables = config.tables.map((table) => {
      if (table.id === tableId) {
        return { ...table, name: value };
      }
      return table;
    });

    setConfig({
      ...config,
      tables: updatedTables,
    });
  };

  const syncDatabase = async () => {
    setSyncing(true);
    try {
      await post({ url: "/api/dbsync", data: { projectId } });
      toast({
        variant: "default",
        title: "Database synced successfully",
      });
      setSyncing(false);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error syncing database",
        description: error.message,
      });
      setSyncing(false);
    }
  };
  function generateUUID() {
    return uuidv4();
  }
  const addTable = () => {
    const tableId = generateUUID();
    const columnId = generateUUID();
    const newTable = {
      id: tableId,
      name: ``,
      columns: [
        {
          id: columnId,
          name: "ID",
          type: "autoIncrementIntegerId",
          dataType: "INTEGER",
          editable: false,
          hidden: false,
          private: false,
          hash: false,
          multiple: false,
          constraints: {
            nullable: false,
            unique: false,
            default: false,
            defaultValue: "",
            primaryKey: true,
          },
        },
      ],
      indexes: [],
      foreignKeys: [],
    };

    const updatedConfig = {
      ...config,
      tables: [...config.tables, newTable],
    };

    setConfig(updatedConfig);
    return newTable.id;
  };

  const addField = (tableId: string) => {
    const newField = {
      id: generateUUID(),
      name: "",
      type: "text",
      dataType: "TEXT",
      editable: true,
      hidden: false,
      private: false,
      hash: false,
      multiple: false,
      constraints: {
        nullable: false,
        unique: false,
        default:
          false ||
          dataCache[
            config?.tables?.find((table) => table.id === selectedTableId)
              ?.name || ""
          ]?.data.length > 0,
        defaultValue: "",
        primaryKey: false,
      },
    };

    const updatedTables = config.tables.map((table) => {
      if (table.id === tableId) {
        return {
          ...table,
          columns: [...table.columns, newField],
        };
      }
      return table;
    });

    setConfig({
      ...config,
      tables: updatedTables,
    });
    setSelectedFieldId(newField.id);
    return newField.id; // Return the new field ID
  };

  const deleteField = (tableId: string, fieldId: string) => {
    setConfig((prevConfig) => {
      let foreignKeyToDelete: any = null;

      // First pass: Identify the foreign key to delete and update the primary table
      const updatedTables = prevConfig.tables.map((table) => {
        if (table.id === tableId) {
          // Identify the foreign key to delete
          foreignKeyToDelete = table.foreignKeys.find(
            (fk) =>
              fk.column === fieldId && fk.relationshipType === "MANY-MANY",
          );

          // Filter out the field to delete
          const updatedColumns = table.columns.filter(
            (field) => field.id !== fieldId,
          );
          const updatedForeignKeys = table.foreignKeys.filter(
            (fk) => fk.column !== fieldId,
          );

          // Update the current table
          return {
            ...table,
            columns: updatedColumns,
            foreignKeys: updatedForeignKeys,
          };
        }
        return table;
      });

      // Second pass: Delete the foreign key column and foreign key object in the referenced table
      const furtherUpdatedTables = updatedTables.map((table) => {
        if (
          foreignKeyToDelete &&
          table.id === foreignKeyToDelete.referenceTable
        ) {
          // Filter out the foreign key column and object to delete using relationshipColumn
          const updatedColumns = table.columns.filter(
            (column) => column.id !== foreignKeyToDelete.relationshipColumn,
          );
          const updatedForeignKeys = table.foreignKeys.filter(
            (foreignKey) =>
              foreignKey.column !== foreignKeyToDelete.relationshipColumn,
          );

          // Update the referenced table
          return {
            ...table,
            columns: updatedColumns,
            foreignKeys: updatedForeignKeys,
          };
        }
        return table;
      });

      const newConfig = {
        ...prevConfig,
        tables: furtherUpdatedTables,
      };

      const data = {
        projectId: projectId,
        newConfiguration: newConfig,
      };

      post({ url: "/api/configuration", data })
        .then((_) => {
          fetchProjectData(projectId);
          toast({
            variant: "default",
            title: `Field deleted successfully`,
          });
        })
        .catch((error) => {
          toast({
            variant: "destructive",
            title: "Error deleting field",
            description: error.message,
          });
          console.error(error);
        });

      return newConfig;
    });

    setShowDeleteFieldModal({
      show: false,
      tableId: null,
      fieldId: null,
    });
  };

  const handleDeleteFieldModal = (tableId: string, fieldId: string) => {
    // console.log("tableId", tableId);
    // console.log("fieldId", fieldId);
    setShowDeleteFieldModal({ show: true, tableId, fieldId });
  };

  const handleCancelDeleteFieldModal = () => {
    setShowDeleteFieldModal({
      show: false,
      tableId: null,
      fieldId: null,
    });
  };

  const handleCancelDeleteTableModal = () => {
    setShowDeleteTableModal({
      show: false,
      tableIndex: null,
    });
  };
  const deleteTable = async (tableId: string) => {
    setConfig((prevConfig) => {
      // Find all foreign keys referencing the table to be deleted
      const foreignKeysToDelete = prevConfig.tables.flatMap((table) =>
        table.foreignKeys
          .filter(
            (fk) =>
              fk.referenceTable === tableId &&
              (fk.relationshipType === "MANY-MANY" ||
                fk.relationshipType === "ONE-MANY" ||
                fk.relationshipType === "ONE-ONE"),
          )
          .map((fk) => ({ ...fk, tableId: table.id })),
      );

      // First pass: Filter out the table to delete
      const updatedTables = prevConfig.tables.filter(
        (table) => table.id !== tableId,
      );

      // Second pass: Remove the foreign key columns and objects from referencing tables
      const furtherUpdatedTables = updatedTables.map((table) => {
        const fksForTable = foreignKeysToDelete.filter(
          (fk) => fk.tableId === table.id,
        );

        if (fksForTable.length > 0) {
          const updatedColumns = table.columns.filter(
            (column) => !fksForTable.some((fk) => fk.column === column.id),
          );

          const updatedForeignKeys = table.foreignKeys.filter(
            (fk) =>
              !fksForTable.some(
                (fkToDelete) => fkToDelete.column === fk.column,
              ),
          );

          return {
            ...table,
            columns: updatedColumns,
            foreignKeys: updatedForeignKeys,
          };
        }

        return table;
      });

      const newConfig = {
        ...prevConfig,
        tables: furtherUpdatedTables,
      };

      const data = {
        projectId: projectId,
        newConfiguration: newConfig,
      };

      post({ url: "/api/configuration", data })
        .then((_) => {
          fetchProjectData(projectId);
          toast({
            variant: "default",
            title: `Table deleted successfully`,
          });
        })
        .catch((error) => {
          toast({
            variant: "destructive",
            title: "Error deleting table",
            description: error.message,
          });
          console.error(error);
        });

      return newConfig;
    });

    setShowDeleteTableModal({
      show: false,
      tableIndex: null, // Consider changing this to tableId: null if your modal state uses tableId
    });
  };

  const handleCreateFieldModal = (selectedTableId: string) => {
    setSelectedTableId(selectedTableId);
    const newFieldIndex: any = addField(selectedTableId as string);

    setSelectedFieldId(newFieldIndex);
    setShowCreateFieldModal(true);
  };

  const handleEditFieldModal = (
    selectedTableId: string,
    selectedFieldId: string,
  ) => {
    setSelectedTableId(selectedTableId);
    setSelectedFieldId(selectedFieldId);
    setIsEditMode(true);
    setShowCreateFieldModal(true);
  };

  const handleCreateFieldModalClose = () => {
    if (!selectedTableId) return; // If no table is selected, exit the function

    const updatedTables = [...config.tables];
    const tableIndex = updatedTables.findIndex(
      (table) => table.id === selectedTableId,
    );

    if (tableIndex === -1) return; // If no table is found, exit the function

    const updatedFields = [...updatedTables[tableIndex].columns];

    if (updatedFields.length > 0) {
      updatedFields.pop(); // Remove the last field if there are any fields
    }

    updatedTables[tableIndex].columns = updatedFields;
    setConfig({
      ...config,
      tables: updatedTables,
    });
    setShowCreateFieldModal(false);
    setIsEditMode(false);
  };

  const handleCancelTable = () => {
    // Close both modals upfront
    setShowCreateFieldModal(false);
    setShowCreateTableModal(false);
    if (isEditMode) {
      // If there are no tables, no further action is needed
      if (config.tables.length === 0) return;

      // Remove the last table and update the state
      const updatedTables = config.tables.slice(0, -1);
      setConfig({
        ...config,
        tables: updatedTables,
      });
    }
    // Reset selected field index
    setIsEditMode(false);
    setSelectedFieldId(null);
    // setSelectedFieldIndex(null);
  };
  const [dataLoading, setDataLoading] = useState(false);
  const [dataCache, setDataCache] = useState<Record<string, DataType>>({});

  const fetchData = async () => {
    const newConfig = config.tables.find(
      (table) => table.id === selectedTableId,
    );
    if (
      !oldConfig.tables.find((badgeTable) => _.isEqual(badgeTable, newConfig))
    ) {
      return;
    }

    setDataLoading(true);
    try {
      const response = await post({
        url: "/api/data",
        data: {
          organizationId: "1",
          projectId: projectId,
          type: "get",

          entity: config.tables.find((table) => table.id === selectedTableId)
            ?.name,
          pagination: {
            page: 1,
            pageSize: 10,
          },
        },
      });
      console.log("Data fetched successfully", response);
      if (response.data) {
        const newData = response.data;
        const tableName = newData.tableName;

        // Use the table name as the key in the dataCache object
        setDataCache((prevCache) => ({
          ...prevCache,
          [tableName]: newData,
        }));
      }
      setDataLoading(false);
      toast({
        variant: "default",
        title: "Data fetched successfully",
      });
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setDataLoading(false);
      toast({
        variant: "destructive",
        title: "Error fetching data",
        description: error.message,
      });
    }
  };

  const handleCreateTable = () => {
    const newTableId = addTable();
    setSelectedTableId(newTableId);
    setShowCreateTableModal(true);
  };

  const handleEditTable = (tableId: string) => {
    setSelectedTableId(tableId);
    setIsEditMode(true);
    setShowCreateTableModal(true);
  };

  useEffect(() => {
    // console.log("config", config);
  }, [config]);
  useEffect(() => {
    if (!selectedTableId && config.tables.length > 0) {
      setSelectedTableId(config?.tables[config.tables.length - 1]?.id);
    }
  }, [config.tables, selectedTableId]);
  return (
    <div className="h-full w-full overflow-hidden">
      {showCreateFieldModal === true && (
        <CreateField
          config={config}
          selectedTableId={selectedTableId}
          selectedFieldId={selectedFieldId}
          setSelectedFieldId={setSelectedFieldId}
          handleFieldChange={handleFieldChange}
          handleForeignKeyChange={handleForeignKeyChange}
          closeModal={handleCreateFieldModalClose}
          setShowCreateFieldModal={setShowCreateFieldModal}
          isEditMode={isEditMode}
          projectId={projectData.projects[0].id}
          fetchProjectData={fetchProjectData}
          setIsEditMode={setIsEditMode}
          dataCache={dataCache}
        />
      )}
      {config.tables.length > 0 ? (
        <TableComp
          config={config}
          handleCreateTable={handleCreateTable}
          setSelectedTableId={setSelectedTableId}
          setShowDeleteTableModal={setShowDeleteTableModal}
          handleCreateFieldModal={handleCreateFieldModal}
          handleDeleteFieldModal={handleDeleteFieldModal}
          handleEditFieldModal={handleEditFieldModal}
          projectName={projectData.projects[0].name}
          setShowRenameModal={setShowRenameModal}
          fetchProjectData={fetchProjectData}
          dbId={dbId}
          oldConfig={oldConfig}
          handleEditTable={handleEditTable}
          selectedTableId={selectedTableId}
          fetchData={fetchData}
          dataCache={dataCache}
          dataLoading={dataLoading}
        />
      ) : (
        <>
          {!loading ? (
            <div className="flex h-screen w-full flex-col items-center justify-center space-y-2">
              <div className="flex flex-col items-center space-y-6">
                <div>
                  {" "}
                  {projectData?.projects?.length > 0 && (
                    <div className="mb-3 text-2xl font-semibold leading-none tracking-tight">
                      {projectData?.projects[0]?.name}
                    </div>
                  )}
                  <p className="text-sm text-slate-500">No tables available </p>
                </div>
                <div className="flex space-x-4">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    Back to Projects
                  </Button>
                  <Button
                    variant="default"
                    onClick={() => {
                      handleCreateTable();
                    }}
                  >
                    <Plus className="mr-2 h-4 w-4" /> Create Table
                  </Button>
                  <div className="flex space-x-4">
                    {!syncing ? (
                      <Button
                        variant={"secondary"}
                        className="mr-4 bg-green-300"
                        onClick={() => syncDatabase()}
                      >
                        Sync Database
                      </Button>
                    ) : (
                      <Button
                        disabled
                        className="mr-4 bg-green-300 text-slate-900"
                      >
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Syncing
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-screen items-center justify-center">
              <Loading />
            </div>
          )}
        </>
      )}

      {showCreateTableModal && config.tables.length > 0 && (
        <CreateTableModal
          projectId={projectData.projects[0].id}
          config={config}
          handleFieldChange={handleFieldChange}
          handleForeignKeyChange={handleForeignKeyChange}
          handleTableNameChange={handleTableNameChange}
          addField={addField}
          handleDeleteFieldModal={handleDeleteFieldModal}
          selectedFieldId={selectedFieldId}
          selectedTableId={selectedTableId}
          setSelectedFieldId={setSelectedFieldId}
          handleCancelTable={handleCancelTable}
          setShowCreateTableModal={setShowCreateTableModal}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}

      {showDeleteFieldModal.show &&
        showDeleteFieldModal.tableId !== null &&
        showDeleteFieldModal.fieldId !== null && (
          <DeleteField
            config={config}
            showDeleteFieldModal={showDeleteFieldModal}
            handleCancelDeleteFieldModal={handleCancelDeleteFieldModal}
            deleteField={deleteField}
          />
        )}

      {showDeleteTableModal.show &&
        showDeleteTableModal.tableIndex !== null && (
          <DeleteTable
            table={config.tables[showDeleteTableModal.tableIndex]}
            handleCancelDeleteTableModal={handleCancelDeleteTableModal}
            deleteTable={deleteTable}
          />
        )}
      {selectedTableId && showRenameModal && (
        <RenameTable
          config={config}
          selectedTableId={selectedTableId}
          setShowRenameModal={setShowRenameModal}
          projectId={projectData.projects[0].id}
          fetchProjectData={fetchProjectData}
        />
      )}
    </div>
  );
};

export default Data;
