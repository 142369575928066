import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import Loading from "../Loading/loading";

const CreateProject = ({
  setNewProjectModal,
  addProject,
  loading,
}: {
  setNewProjectModal: (value: boolean) => void;
  addProject: any;
  loading: boolean;
}) => {
  const [projectName, setProjectName] = useState("");
  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    addProject(projectName);
  };

  return (
    <div className="absolute left-0 top-0 z-20 flex h-screen w-screen items-center justify-center overflow-hidden bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <form
        onSubmit={onSubmit}
        className="grid min-h-[230px] w-[40vw] min-w-[332px] gap-4 justify-self-center"
      >
        <Card className="flex w-full justify-start">
          {!loading ? (
            <CardHeader className="w-full">
              <CardTitle className="w-fit">Create New Project</CardTitle>
              <CardContent className="px-0">
                <div className="grid w-full gap-2 py-4">
                  <Label htmlFor="project_name" className="text-left">
                    Name
                  </Label>
                  <Input
                    id="project_name"
                    type="project_name"
                    placeholder="Project Name"
                    value={projectName}
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                    required
                  />
                </div>
              </CardContent>
              <div className="flex w-full justify-end">
                <div className="space-x-2">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => {
                      setNewProjectModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant={"default"} type="submit">
                    Create
                  </Button>
                </div>
              </div>
            </CardHeader>
          ) : (
            <Loading />
          )}
        </Card>
      </form>
    </div>
  );
};

export default CreateProject;
