import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import React, { useState } from "react";
import Loading from "../Loading/loading";
import { post } from "@/lib/rest";
import { toast } from "@/components/ui/use-toast";

const RenameTable = ({
  config,
  selectedTableId,
  setShowRenameModal,
  projectId,
  fetchProjectData,
}: any) => {
  const initialName = config.tables.find(
    (table: any) => table.id === selectedTableId,
  ).name;
  const [tableName, setTableName] = useState(initialName);
  const [loading, setLoading] = useState(false);

  const renameTable = (newName: string) => {
    const updatedTables = config.tables.map((table: any) => {
      if (table.id === selectedTableId) {
        return { ...table, name: newName };
      }
      return table;
    });

    const newConfig = { ...config, tables: updatedTables };
    return newConfig;
  };

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    const newConfig = renameTable(tableName);
    const data = {
      projectId: projectId,
      newConfiguration: newConfig,
    };

    post({ url: "/api/configuration", data })
      .then((_) => {
        fetchProjectData(projectId);
        toast({
          variant: "default",
          title: `Table renamed successfully`,
        });
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Error renaming table",
          description: error.message,
        });
        console.error(error);
      });

    setLoading(false);
    setShowRenameModal(false);
    // addProject(projectName);
  };
  return (
    <div className="absolute left-0 top-0 z-20 flex h-screen w-screen items-center justify-center overflow-hidden bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <form
        onSubmit={onSubmit}
        className="grid min-h-[230px] w-[40vw] min-w-[332px] gap-4 justify-self-center"
      >
        <Card className="flex w-full justify-start">
          {!loading ? (
            <CardHeader className="w-full">
              <CardTitle className="w-fit">Rename Table</CardTitle>
              <CardContent className="px-0">
                <div className="grid w-full gap-2 py-4">
                  <Label htmlFor="project_name" className="text-left">
                    Name
                  </Label>
                  <Input
                    id="project_name"
                    type="project_name"
                    placeholder="Project Name"
                    value={tableName}
                    onChange={(e) => {
                      setTableName(e.target.value);
                    }}
                    required
                  />
                </div>
              </CardContent>
              <div className="flex w-full justify-end">
                <div className="space-x-2">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => {
                      setShowRenameModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant={"default"} type="submit">
                    Update
                  </Button>
                </div>
              </div>
            </CardHeader>
          ) : (
            <Loading />
          )}
        </Card>
      </form>
    </div>
  );
};

export default RenameTable;
