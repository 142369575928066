import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  CalendarClock,
  ChevronDown,
  FileDigit,
  FilePen,
  FileType2,
  Hash,
  Key,
  Loader2,
  Pencil,
  Plus,
  ToggleLeft,
  Trash2,
  Image,
  File,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Data, DatabaseConfig } from "../interface/config";
import { post } from "@/lib/rest";
import { useParams } from "react-router-dom";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { DataEditor } from "./Editor";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { Badge } from "@/components/ui/badge";
import _ from "lodash";

// Zod schema definitions
const uniqueFieldNames = (columns: any[]) => {
  const fieldNames = columns.map((column: { name: any }) => column?.name);
  const uniqueFieldNames = new Set(fieldNames);
  return uniqueFieldNames.size === fieldNames.length;
};

const fieldSchema = z.object({
  id: z.string(),
  name: z.string().min(1, "Field name is required"),
  type: z.enum([
    "text",
    "autoIncrementIntegerId",
    "number",
    "dateTime",
    "foreignKey",
    "boolean",
    "image",
    "file",
  ]),
  dataType: z.string().min(1, "Field data type is required"),
  editable: z.boolean().optional(),
  hidden: z.boolean().optional(),
  private: z.boolean(),
  constraints: z
    .object({
      nullable: z.boolean(),
      unique: z.boolean(),
      default: z.boolean(),
      defaultValue: z.union([z.string(), z.number()]),
      primaryKey: z.boolean(),
    })
    .refine(
      (constraints) => {
        if (constraints.default) {
          return constraints.defaultValue !== "";
        }
        return true;
      },
      {
        message: "Default value must not be empty",
      },
    ),
});

const foreignKeySchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  column: z.string(),
  referenceTable: z.string().min(1, "Reference table is required"),
  referenceColumn: z.string().min(1, "Reference column is required"),
  onDelete: z.enum(["RESTRICT", "CASCADE", "SET NULL", "NO ACTION"]),
  onUpdate: z.enum(["RESTRICT", "CASCADE", "SET NULL", "NO ACTION"]),
});

const tableSchema = z
  .object({
    id: z.string(),
    name: z.string().min(1, "Table name is required"),
    columns: z.array(fieldSchema).refine(uniqueFieldNames, {
      message: "Field names must be unique",
    }),
    indexes: z
      .array(
        z.object({
          id: z.string(),
          name: z.string().min(1, "Index name is required"),
          columns: z.array(z.string()),
          unique: z.boolean(),
        }),
      )
      .optional(),
    foreignKeys: z.array(foreignKeySchema),
  })
  .superRefine((data, context) => {
    const foreignKeyColumns = data.columns
      .filter((column: any) => column.type === "foreignKey")
      .map((column: any) => column.id);
    const definedForeignKeys = data.foreignKeys.map((fk) => fk.column);

    if (
      !foreignKeyColumns.every((columnId) =>
        definedForeignKeys.includes(columnId),
      )
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Every foreign key field must have a corresponding entry in the foreignKeys array",
        path: ["foreignKeys"],
      });
    }
  });

const databaseConfigSchema = z.object({
  version: z.string().min(1, "Version is required"),
  database: z.string().min(1, "Database name is required"),
  tables: z.array(tableSchema),
});

const TableComp = ({
  config,
  handleCreateTable,
  setSelectedTableId,
  setShowDeleteTableModal,
  handleCreateFieldModal,
  handleDeleteFieldModal,
  handleEditFieldModal,
  projectName,
  setShowRenameModal,
  fetchProjectData,
  dbId,
  oldConfig,
  handleEditTable,
  selectedTableId,
  fetchData,
  dataCache,
  dataLoading,
}: {
  config: DatabaseConfig;
  handleCreateTable: any;
  setSelectedTableId: any;
  setShowDeleteTableModal: any;
  handleCreateFieldModal: any;
  handleDeleteFieldModal: any;
  handleEditFieldModal: any;
  projectName: string;
  setShowRenameModal: any;
  fetchProjectData: any;
  dbId: any;
  oldConfig: DatabaseConfig;
  handleEditTable: any;
  selectedTableId: any;
  fetchData: any;
  dataCache: any;
  dataLoading: any;
}) => {
  interface FieldTypeToIconMap {
    [key: string]: JSX.Element | null;
  }
  const fieldTypeToIconMap: FieldTypeToIconMap = {
    text: <FileType2 className="h-4 w-4" />,
    autoIncrementIntegerId: <Hash className="h-4 w-4" />,
    number: <FileDigit className="h-4 w-4" />,
    dateTime: <CalendarClock className="h-4 w-4" />,
    foreignKey: <Key className="h-4 w-4" />,
    boolean: <ToggleLeft className="h-4 w-4" />,
    image: <Image className="h-4 w-4" />,
    file: <File className="h-4 w-4" />,
    // Add other mappings as needed
  };

  function getIconByFieldType(fieldType: string | number) {
    return fieldTypeToIconMap[fieldType] || null;
  }

  const [isEditorMode, setIsEditorMode] = useState(true);
  const [script, setScript] = useState<any>("");
  const [syncing, setSyncing] = useState(false);
  const { projectId } = useParams();

  useEffect(() => {
    if (selectedTableId) {
      fetchData();
    }
  }, [selectedTableId]);
  const selectedTableName = config.tables.find(
    (table) => table.id === selectedTableId,
  )?.name;
  async function saveConfig(config: any) {
    try {
      const parsedConfig = JSON.parse(config);
      databaseConfigSchema.parse(parsedConfig);
    } catch (e) {
      if (e instanceof z.ZodError) {
        // setValidationErrors(JSON.stringify(e.errors, null, 2));
        toast({
          variant: "destructive",
          title: "Invalid Configuration",
          description: JSON.stringify(e.errors, null, 2),
        });
        console.error(e.errors);
        return;
      } else {
        // setValidationErrors("Invalid JSON format");
        toast({
          variant: "destructive",
          title: "Invalid JSON format",
          description: "Please check the JSON format and try again",
        });
        return;
      }
    }
    try {
      await post({
        url: "/api/configuration",
        data: { projectId, newConfiguration: JSON.parse(config) },
      });
      // Display success toast
      fetchProjectData(projectId);
      toast({
        variant: "default",
        title: "Configuration saved successfully",
      });
    } catch (error: any) {
      // Display error toast
      toast({
        variant: "destructive",
        title: "Error saving configuration",
        description: error.message,
      });
    }
  }
  const syncDatabase = async () => {
    setSyncing(true);
    try {
      await post({ url: "/api/dbsync", data: { projectId } });
      fetchProjectData(projectId);
      toast({
        variant: "default",
        title: "Database synced successfully",
      });
      setSyncing(false);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error syncing database",
        description: error.message,
      });
      setSyncing(false);
    }
  };

  return (
    <div className="w-screen p-4">
      <Tabs defaultValue={config.tables[0].name} className="w-full">
        <div className="flex min-w-[1060px] items-center justify-between">
          <TabsList className="relative flex w-fit justify-start">
            {!isEditorMode &&
              config.tables.map((table: any, index: number) => (
                <Popover key={`${table.id}${index}popover`}>
                  <TabsTrigger
                    className="min-w-32"
                    key={table.id}
                    value={table.name}
                    onClick={() => {
                      setSelectedTableId(table.id);
                    }}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="mr-2 flex">
                        {table?.name}
                        <div>
                          {oldConfig.tables.find((badgeTable) =>
                            _.isEqual(badgeTable, table),
                          ) ? (
                            <></>
                          ) : (
                            <Badge
                              variant={"outline"}
                              className="ml-3 text-slate-400"
                            >
                              draft
                            </Badge>
                          )}
                        </div>
                      </div>
                      <PopoverTrigger>
                        {" "}
                        <>
                          {" "}
                          <ChevronDown className="h-4 w-4" />
                        </>
                      </PopoverTrigger>
                    </div>
                  </TabsTrigger>
                  <PopoverContent className="w-[150px] font-normal">
                    <Button
                      variant="ghost"
                      className="flex w-full items-center justify-start"
                      onClick={() => {
                        setSelectedTableId(table.id);
                        handleEditTable(table.id);
                      }}
                    >
                      <Pencil className="mr-2 h-4 w-4" />
                      <>Edit</>
                    </Button>{" "}
                    <Button
                      variant="ghost"
                      className="flex w-full items-center justify-start"
                      onClick={() => {
                        setSelectedTableId(table.id);
                        setShowRenameModal(true);
                      }}
                    >
                      <FilePen className="mr-2 h-4 w-4" />
                      <>Rename</>
                    </Button>{" "}
                    <Button
                      variant="ghost"
                      className="flex w-full items-center justify-start text-red-600"
                      onClick={() =>
                        setShowDeleteTableModal({
                          show: true,
                          tableIndex: index,
                        })
                      }
                    >
                      <Trash2 className="mr-2 h-4 w-4" />

                      <>Delete</>
                    </Button>
                  </PopoverContent>
                </Popover>
              ))}
            {!isEditorMode && (
              <Button
                variant="outline"
                size={"icon"}
                className="absolute -right-10 top-1 h-8 w-8 p-0"
                onClick={() => {
                  handleCreateTable();
                }}
              >
                <Plus className="h-4 w-4" />
              </Button>
            )}
          </TabsList>
          <div className="flex items-center">
            <div className="mr-4 flex items-center space-x-2">
              <Switch
                id="editor-mode"
                checked={isEditorMode}
                onCheckedChange={() => setIsEditorMode(!isEditorMode)}
              />
              <Label htmlFor="editor-mode">Editor Mode</Label>
            </div>
            {!syncing ? (
              <Button
                variant={"secondary"}
                className="mr-4 bg-green-300"
                onClick={() => syncDatabase()}
              >
                Sync
              </Button>
            ) : (
              <Button disabled className="mr-4 bg-green-300 text-slate-900">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Syncing
              </Button>
            )}

            {!dataLoading ? (
              <Button className="mr-4" onClick={() => fetchData()}>
                Fetch Data
              </Button>
            ) : (
              <Button disabled className="mr-4">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Fetching Data
              </Button>
            )}
            {isEditorMode && (
              <Button
                variant={"default"}
                onClick={() => saveConfig(script)}
                className="mr-4"
              >
                Save
              </Button>
            )}
            <div className="flex flex-col items-start">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="font-semibold">{projectName}</div>
                  </TooltipTrigger>
                  <TooltipContent>Project Name</TooltipContent>
                </Tooltip>
                <div className="font-mono text-xs text-gray-600">{dbId}</div>
              </TooltipProvider>
            </div>
          </div>
        </div>
        {!isEditorMode &&
          config.tables.map((table: any, tableindex: number) => (
            <TabsContent key={`${table.id}`} value={table.name}>
              <div className="relative min-w-[1060px] rounded-lg border border-slate-300">
                <div className="absolute left-0 top-0 -z-10 h-[42px] w-full rounded-t-lg bg-slate-300" />
                <Table className="w-full overflow-hidden rounded-t-lg text-[14px] font-medium text-black">
                  <TableHeader className="relative h-[42px] w-full bg-slate-100">
                    <TableRow className="relative h-fit w-fit">
                      {table.columns.map((column: any, index: number) => (
                        <Popover key={`${table.id}${index}popover2`}>
                          <TableHead
                            key={column.name}
                            className="h-[42px] w-[200px] items-center border-r p-[11px] text-black"
                          >
                            <div className="flex items-center justify-between">
                              {" "}
                              <div className="flex items-center">
                                <div className="mr-2 w-fit">
                                  {" "}
                                  {getIconByFieldType(column.type)}
                                </div>
                                <>{column.name}</>
                              </div>
                              <PopoverTrigger>
                                {" "}
                                <>
                                  {" "}
                                  <ChevronDown className="h-4 w-4" />
                                </>
                              </PopoverTrigger>
                            </div>
                          </TableHead>
                          <PopoverContent className="w-[200px] p-2 font-normal">
                            {!column.constraints.primaryKey &&
                              column.editable &&
                              column.type !== "foreignKey" && (
                                <>
                                  <Button
                                    variant="ghost"
                                    className="flex w-full items-center justify-start"
                                    onClick={() => {
                                      handleEditFieldModal(table.id, column.id);
                                    }}
                                  >
                                    <Pencil className="mr-2 h-4 w-4" />
                                    <>Edit</>
                                  </Button>{" "}
                                </>
                              )}
                            <Button
                              variant="ghost"
                              className="flex w-full items-center justify-start"
                              onClick={() => {}}
                            >
                              <ArrowUpNarrowWide className="mr-2 h-4 w-4" />
                              <>Sort Ascending</>
                            </Button>
                            <Button
                              variant="ghost"
                              className="flex w-full items-center justify-start"
                              onClick={() => {}}
                            >
                              <ArrowDownNarrowWide className="mr-2 h-4 w-4" />
                              <>Sort Descending</>
                            </Button>{" "}
                            {!column.constraints.primaryKey && (
                              <>
                                <Separator />
                                <Button
                                  variant="ghost"
                                  className="flex w-full items-center justify-start text-red-600"
                                  onClick={() => {
                                    handleDeleteFieldModal(table.id, column.id);
                                  }}
                                >
                                  <Trash2 className="mr-2 h-4 w-4" />

                                  <>Delete</>
                                </Button>
                              </>
                            )}
                          </PopoverContent>
                        </Popover>
                      ))}
                      <TableHead className="h-[30px] w-fit border-r px-2 text-black">
                        <Button
                          variant={"ghost"}
                          className="m-auto h-8 w-8 p-px hover:bg-slate-200"
                          onClick={() => {
                            handleCreateFieldModal(table.id);
                          }}
                        >
                          <Plus className="h-4 w-4 cursor-pointer rounded-lg p-0" />
                        </Button>
                      </TableHead>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {/* Replace this with actual data rows if available */}

                    {selectedTableName && dataCache[selectedTableName] ? (
                      dataCache[selectedTableName].data.length > 0 ? (
                        dataCache[selectedTableName].data.map(
                          (rowData: any, index: number) => (
                            <TableRow key={index} className="text-left">
                              {table.columns.map(
                                (column: any, colIndex: number) => (
                                  <TableCell
                                    key={`${column.name}${colIndex}`}
                                    className="h-[42px] border-r p-[11px]"
                                  >
                                    {rowData[column.name] !== undefined ? (
                                      <div>{rowData[column.name] + ``}</div>
                                    ) : (
                                      <>No data available</>
                                    )}
                                  </TableCell>
                                ),
                              )}
                              <TableCell className="h-[42px] border-b border-b-white p-[11px]"></TableCell>
                            </TableRow>
                          ),
                        )
                      ) : (
                        <TableRow className="text-left">
                          <TableCell
                            colSpan={table.columns.length + 1}
                            className="h-[42px] border-r p-[11px]"
                          >
                            No data available
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow className="text-left">
                        <TableCell
                          colSpan={table.columns.length + 1}
                          className="h-[42px] border-r p-[11px]"
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TabsContent>
          ))}
        {isEditorMode && (
          <DataEditor
            configuration={config}
            script={script}
            setScript={setScript}
          />
        )}
      </Tabs>
    </div>
  );
};

export default TableComp;
