import React, { useEffect } from "react";
import Logo from "../../assets/logo.svg";
import { Settings } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

const NavComponent = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  useEffect(() => {
    navigate(`/project/${projectId}/data`);
  }, []);
  return (
    <nav className="flex h-12 items-center justify-between bg-slate-900">
      <div className="flex h-full items-center justify-start px-5">
        <img
          src={Logo}
          alt="logo"
          style={{ height: 15, width: "auto" }}
          className="h-full w-full cursor-pointer object-cover dark:brightness-[0.2] dark:grayscale"
          onClick={() => {
            navigate("/home");
          }}
        />
      </div>
      <Tabs defaultValue="data" className="w-[400px] bg-transparent">
        <TabsList className="bg-transparent">
          <TabsTrigger
            value="data"
            onClick={() => navigate(`/project/${projectId}/data`)}
          >
            Data
          </TabsTrigger>
          <TabsTrigger
            value="ui"
            onClick={() => navigate(`/project/${projectId}/ui`)}
          >
            UI
          </TabsTrigger>
          <TabsTrigger
            value="actions"
            onClick={() => navigate(`/project/${projectId}/workflow`)}
          >
            Actions
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <div className="px-4 text-white">
        <Settings />
      </div>
    </nav>
  );
};

export default NavComponent;
